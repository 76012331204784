import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { environment as env } from '../../../../environments/environment';
import { first } from 'rxjs/operators';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  YOUR_SITE_KEY = '6Le6JjQbAAAAAPgCD0_vPkFL64tMwj9peSOZ31PK';
  sessionId: any;
  categoryCode: any;
  scanCode: any;
  checkQRCode2: any;
  notRegistered: boolean = false;
  productImage: any;
  passwordError: boolean = false;
  registerForm: any;
  type: any;
  iSprintData: any;
  @ViewChild('alreadyScanned', { static: true }) alreadyScanned: ModalDirective;
  @ViewChild('loginLanding', { static: true }) loginLanding: ModalDirective;
  @ViewChild('loginPopup', { static: true }) loginPopup: ModalDirective;
  @ViewChild('exceedLimitModal', { static: true })
  exceedLimitModal: ModalDirective;
  customerName: any;
  suspicious_count = 0;
  confidence: any;
  weight: any;
  latitude: string;
  longitude: string;
  isprintPoint: any;
  // exceedLimitModal: any;
  prodImage: any;
  sfCode: any;
  sku: any;
  user: any;
  userPoints: any;
  suspiciousCount: number = 0;
  isWeightEmpty: any;
  productName: any;
  productType: string;
  weightInUnits: any;
  weightFinal: any;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private bsService: BugsnagService
  ) {
    this.type = this.route.snapshot.params.id;
    console.log(this.type, 'type---');
    this.getPosition();
  }

  ngOnInit(): void {
    this.loginPopup.show();

    window.scrollTo({ top: 0 });
    console.log("key: ",env.LICENSEKEY)
    let scanCodeData = JSON.parse(sessionStorage.getItem('iSprintData'));
    if (scanCodeData != null) {
      let iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));
      this.iSprintData = iSprintData;
      this.categoryCode = iSprintData?.product?.categoryCode;
      console.log(this.categoryCode, 'iSprintData==========');
      this.scanCode = sessionStorage.getItem('scanCode');
      this.checkQRCode2 = iSprintData?.uaid_info_type;
      console.log(this.checkQRCode2, 'this.checkQRCode2')
      this.productImage = iSprintData?.product_image;
      console.log(this.productImage, 'this.productImage--');
      let sku = sessionStorage.getItem('sku');
      this.sku = sku;
      console.log(sku, 'sku at pg load');
    }

    console.log(this.iSprintData?.identifier, 'this.iSprintData?.identifier')

    if (this.checkQRCode2 == 2 && this.iSprintData?.identifier == undefined) {
      this.showLandingPopup();
    }

    this.loginForm = this.formBuilder.group({
      // email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobile: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(10),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?=.*[\\W_])(?=.*\\d).{8,}$'),
          // Validators.pattern('(?=.*[a-z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
        ],
      ],
      countryCode: [''],
      remember_me: [false],
      recaptcha: ['', Validators.required],
    });

    this.checkRem();
    if (
      (this.checkQRCode2 == 2 || this.checkQRCode2 != undefined) &&
      this.type == '11'
    ) {
      this.showLandingPopup();
    }
  }
  closeloginPopup(){
    this.loginPopup.hide();
  }

  getPosition() {
    let posresp;
    this.apiService.getPosition().then((pos) => {
      posresp =pos
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
    },err=>{
     
      this.bsService.notifyError(err);
      this.spinner.hide()
      this.apiService.showErrorMsg('Something went wrong, please try again')
      console.log(err, '=== get customer error =======')
    });
  }

  get fVal() {
    return this.loginForm.controls;
  }
  onLoginFormSubmit() {
    this.submitted = true;
    console.log(this.loginForm.invalid, 'this.loginForm.invalid');
    console.log(this.loginForm.valid, 'this.loginForm.valid');
    if (this.loginForm.invalid) {
      console.log(this.loginForm.value, 'inside invalid');
      console.log(this.loginForm.controls, 'controls');
      return;
    } else {
      let ph_no = this.loginForm.value ? this.loginForm.value.mobile : '';
      let password = this.loginForm.value ? this.loginForm.value.password : '';
      sessionStorage.setItem('mobileNo', ph_no.replace(/\b0+/g, ''));
      let mobileNumber = ph_no.replace(/\b0+/g, '');
      let formData = {
        identifierType: 'MOBILE',
        identifierValue: mobileNumber,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        password: password,
        confirmPassword: password,
      };
      this.spinner.show();
      this.spinner.show();
      let tokengenresp;
      this.apiService
        .tokenGenerate(formData)
        .pipe(first())
        .subscribe((res) => {
          tokengenresp = res;
          this.spinner.show();
          let myuuid = uuidv4();
          this.spinner.hide();
          if (res['status'].code == 200) {
            if (res['user'].userRegisteredForPassword == false) {
              console.log('navigated to register--');
              this.notRegistered = true;
              this.router.navigate(['/register'])
            } else {
              this.notRegistered = false;
              this.sessionId = res['user'].sessionId;
              let formData = {
                identifierType: 'MOBILE',
                identifierValue: mobileNumber,
                deviceId: env.DEVICE_ID,
                brand: env.BRAND,
                sessionId: this.sessionId,
                password: password,
              };
              this.spinner.show();
              let passvalresp
              this.apiService
                .passwordValidate(formData)
                .pipe(first())
                .subscribe((res) => {
                  this.spinner.hide();
                  passvalresp = res;

                  if (res['status'].code == 200) {
                    console.log(res['auth'].token,'res token')
                    sessionStorage.setItem('authToken', res['auth'].token);
                    sessionStorage.setItem('authKey', res['auth'].key);
                    if (this.checkQRCode2 == 1 || this.checkQRCode2 == '') {
                      let data = {
                        data: 'login',
                      };

                      let mobile = sessionStorage.getItem('mobileNo');
                      let tokenKey = sessionStorage.getItem('authToken');
                      let getcustresp;

                      this.apiService.getCapCustomer(mobile, tokenKey).subscribe((res: any) => {
                        getcustresp = res;
                        if (res.status.code == 200) {
                          this.router.navigate(['/carepoints']);
                        } else {
                          this.router.navigate(['/register']);
                        }
                      }, err => {
                       
                        this.bsService.notifyError(err, tokenKey);
                        this.spinner.hide()
                        this.apiService.showErrorMsg('Something went wrong, please try again')
                        console.log(err, '=== get customer error =======')
                        console.log(mobile, '=== get customer mobile =======');
                        console.log(err?.error?.message, '=== get customer error message =======');
                        console.log(err?.error?.code, '=== get customer error code =======');
                        console.log(err)
                      })
                      console.log('true');
                      return;


                    }
                    
                    if (
                      res['user'].userRegisteredForPassword == true ||
                      res['status'].code == 1515
                    ) {
                      let mobile = sessionStorage.getItem('mobileNo');
                      let tokenKey = sessionStorage.getItem('authToken');
                      let getcustresp;
                      this.spinner.show();
                      this.apiService.getCapCustomer(mobile, tokenKey).subscribe((res: any) => {
                        getcustresp = res;
                        this.spinner.hide();
                        if (res.status.code == 200) {
                          sessionStorage.setItem('userPassword', password);
                          // Update customer with last login
                          let filledFormValues = this.getuserForm();
                          let getMobile = sessionStorage.getItem('mobileNo');
                          let token = sessionStorage.getItem('authToken');
                          this.spinner.show();
                          let updatecustres;
                          this.apiService
                            .updateCapCustomer(filledFormValues, getMobile, token)
                            .pipe(first())
                            .subscribe(
                              (res) => {
                                updatecustres = res;
                                let myuuid = uuidv4();
                                let loggerFormData = {
                                  unique_id: myuuid,
                                  message: JSON.stringify(res),
                                };
                               
                                this.spinner.hide();
                                if (res['status'].code == 200) {
                                  console.log('updated last login');
                                } else {
                                  console.log(res['status'].message);
                                  //this.toastr.info(res['status'].message)
                                }
                              },
                              (err) => {
                               
                      
                                this.bsService.notifyError(err, filledFormValues);
                                this.spinner.hide()
                                this.apiService.showErrorMsg('Something went wrong, please try again')
                                console.log(err, '=== get customer error =======')
                                console.log(mobile, '=== get customer mobile =======');
                                console.log(err?.error?.message, '=== get customer error message =======');
                                console.log(err?.error?.code, '=== get customer error code =======');
                              }
                            );
                          if (this.loginForm.value.remember_me == true) {
                            // if user sets remember me save user
                            sessionStorage.setItem('RemMobileNo', ph_no);
                            sessionStorage.setItem('RemPasswd', password);
                            sessionStorage.setItem('RemUser', '1');
                          } else {
                            this.apiService.setRememberUser(false);
                            sessionStorage.setItem('RemUser', '0');
                          }
    
                          if (
                            // this.checkQRCode2 == 2 ||
                            // this.checkQRCode2 != undefined
                            this.checkQRCode2 == 2 && this.iSprintData?.identifier == undefined
                          ) {
                            // scanned QR code is type 2
                            console.log(this.checkQRCode2, 'inside typr 2 check');
                            this.onClickCancelModalone(this.scanCode);
                            // this.apiService.getPosition().then((pos) => {
                            //   let lat = pos.lat;
                            //   let lang = pos.lng;
                            //   let latitude = lat.toString();
                            //   let langitude = lang.toString();
                            //   let getMobile = sessionStorage.getItem('mobileNo');
                            //   let getAuthToken = sessionStorage.getItem('authToken');
                            //   let productName = sessionStorage.getItem('productName');
                            //   let sku = sessionStorage.getItem('sku');
                            //   let formData = {
                            //     scan_code: this.scanCode,
                            //     mobile: getMobile,
                            //     cap_device_id: env.DEVICE_ID,
                            //     custom_fields: [
                            //       {
                            //         name: 'TransLat',
                            //         value: latitude,
                            //       },
                            //       {
                            //         name: 'TransLong',
                            //         value: langitude,
                            //       },
                            //       {
                            //         name: 'productName',
                            //         value: productName,
                            //       },
                            //       {
                            //         name: 'category_code_trans',
                            //         value: this.categoryCode
                            //           ? this.categoryCode
                            //           : '',
                            //       },
                            //       {
                            //         name: 'sfproductcode',
                            //         value: sku,
                            //       },
                            //       {
                            //         name: 'productimage',
                            //         value: this.productImage,
                            //       },
                            //     ],
                            //   };
                            //   this.spinner.show();
                            //   this.apiService
                            //     .addTransaction(formData, getAuthToken)
                            //     .pipe(first())
                            //     .subscribe(
                            //       (res) => {
                            //         this.spinner.hide();
                            //         if (res['status'] == 'success') {
                            //           console.log('add trans success---');
                            //           this.router.navigate(['/carepoints']);
                            //         } else {
                            //         }
                            //       },
                            //       (err) => {
                            //         this.spinner.hide();
                            //         this.alreadyScanned.show();
                            //         console.log(
                            //           err,
                            //           '=== get customer error ======='
                            //         );
                            //       }
                            //     );
                            // });
    
                            // position api
                          } else {
                            // scanned code is type 1
                            this.router.navigate(['/carepoints']);
                          }
                        } else {
                          this.router.navigate(['/register']);
                        }
                      }, err => {
                       
                        this.bsService.notifyError(err, tokenKey);
                        this.spinner.hide()
                        this.apiService.showErrorMsg('Something went wrong, please try again')
                        console.log(err, '=== get customer error =======')
                        console.log(err)
                      })
                      console.log('true');
                      return;
                     
                    }
                  } else if (
                    res['status'].code == 1528 ||
                    res['status'].message ==
                    'Password entered is invalid, Please try again'
                  ) {
                    console.log('password error--');
                    this.passwordError = true;
                  } else {
                    console.log('password error--');
                    this.passwordError = true;
                    return;
                  }
                },err=>{
                  
        
                  this.bsService.notifyError(err, formData);
                  this.spinner.hide()
                  this.apiService.showErrorMsg('Something went wrong, please try again')
                  console.log(err, '=== get customer error =======')
                }); // password validate
            }
          } // token generate success - 200
        }, err => {
        
          this.bsService.notifyError(err, formData);
          this.spinner.hide()
          this.apiService.showErrorMsg('Something went wrong, please try again')
          console.log(err, '=== get customer error =======')
        });
        
         // token generate
    }
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  errored(ev) {
    console.warn(`reCAPTCHA error encountered`);
  }

  getSiteKey(){
    return env.SITE_KEY;
  }

  passwordInput(ev) {
    if (ev.keyCode == 8) {
      this.notRegistered = false;
      this.passwordError = false;
    }
  }

  onlyNumbers(ev) {
    let onlynum = /^[0-9]*$/;

    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  closeScannedModal() {
    this.alreadyScanned.hide();
    this.router.navigate(['/carepoints']);
  }

  getuserForm() {
    let date = new Date();
    return (this.registerForm = {
      root: {
        customer: [
          {
            mobile: sessionStorage.getItem('mobileNo'),
            custom_fields: {
              field: [
                {
                  name: 'last_login_date',
                  value: moment(date).format('YYYY-MM-DD HH:MM:SS'),
                },
              ],
            },
          },
        ],
      },
    });
  }

  checkRem() {
    let remMobile = sessionStorage.getItem('RemMobileNo');
    let remPasswd = sessionStorage.getItem('RemPasswd');
    let remUser = sessionStorage.getItem('RemUser');
    if (remUser == '1') {
      this.loginForm.patchValue({
        mobile: remMobile,
        password: remPasswd,
        remember_me: true,
      });
    } else {
      sessionStorage.removeItem('RemMobileNo');
      sessionStorage.removeItem('RemPasswd');
    }
  }

  showLandingPopup() {
    this.loginLanding.show();
  }

  closeloginLandingModal() {
    this.loginLanding.hide();
  }
  parseFunction(value) {
    return JSON.parse(value);
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let getcustresp
    this.apiService.getCapCustomer(mobile, token).subscribe(
      (res) => {
        this.spinner.hide();
        getcustresp = res;
        console.log(res, 'res---');
        console.log(res['customers']['customer'][0].user_id, 'used id---');
        sessionStorage.setItem(
          'abbottUserId',
          res['customers']['customer'][0].user_id
        );
        this.user = res['customers']['customer'][0];
        this.userPoints = res['customers']['customer'][0]?.loyalty_points
          ? res['customers']['customer'][0]?.loyalty_points
          : '0';
        console.log(this.userPoints, 'this.userPoints');
        let lifetimepoints = res['customers']['customer'][0].lifetime_purchases;
        console.log(this.userPoints, 'this.userPoints');
        console.log(lifetimepoints, 'lifetimepoints');
        this.customerName = this.user.firstname + ' ' + this.user.lastname;
        console.log(this.customerName);
        // Block the user
        // let block = res['customers']['customer'][0].custom_fields.field;
        // let blockUser = block.filter((filt) => {
        //   return filt.name == 'block_count';
        // });
        // console.log(blockUser, 'blockUser===');
        // if (blockUser[0]?.value == '1') {
        //   this.spinner.hide();
        //   sessionStorage.clear();
        //   this.router.navigate(['/block-notice']);
        // }
        this.user.custom_fields.field.forEach((element) => {
          if (element.name === 'suspicious_count') {
            this.suspiciousCount = parseInt(element.value);
          }
        });
      },
      (err) => {
       

        this.bsService.notifyError(err);
        this.spinner.hide()
        this.apiService.showErrorMsg('Something went wrong, please try again')
        console.log(err, '=== get customer error =======')
        console.log(mobile, '=== get customer mobile =======');
        console.log(err?.error?.message, '=== get customer error message =======');
        console.log(err?.error?.code, '=== get customer error code =======');
      }
    );
  }

  isEmpty(weight) {
    if (weight == '' || weight == undefined || weight == null) {
      return false;
    } else {
      return true;
    }
  }

  getWeightLimit(weight) {
    for (let i = 0; i < weight.length; i++) {
      let flag: Boolean = isNaN(weight[i])
      if (!flag) {
        weight = weight.split(weight[i - 1])
      }
    }
    return weight;

  }



  onClickCancelModalone(scannedCode) {
    // this.congratsModalStepOne.hide();
    // this.processingModal.show();

    let mobileNo = sessionStorage.getItem('mobileNo');
    let tokenAuth = sessionStorage.getItem('authToken');
    let getcustresp
    this.apiService.getCapCustomer(mobileNo, tokenAuth).subscribe(
      (res: any) => {
        getcustresp = res;
       
        if (res['status'].code == 200) {
          console.log(res, 'res---');
          console.log(res['customers']['customer'][0].user_id, 'used id---');
          sessionStorage.setItem(
            'abbottUserId',
            res['customers']['customer'][0].user_id
          );
          this.user = res['customers']['customer'][0];
          this.userPoints = res['customers']['customer'][0]?.loyalty_points
            ? res['customers']['customer'][0]?.loyalty_points
            : '0';
          console.log(this.userPoints, 'this.userPoints');
          let lifetimepoints = res['customers']['customer'][0].lifetime_purchases;
          console.log(this.userPoints, 'this.userPoints');
          console.log(lifetimepoints, 'lifetimepoints');
          this.customerName = this.user.firstname + ' ' + this.user.lastname;
          console.log(this.customerName);
          this.user.custom_fields.field.forEach((element) => {
            if (element.name === 'suspicious_count') {
              this.suspiciousCount = parseInt(element.value);
            }
          });

          // ISprint and Gpass
          let mobile = sessionStorage.getItem('mobileNo');
          let scanCode = sessionStorage.getItem('scanCode');

          let token = sessionStorage.getItem('authToken');

          if (this.iSprintData.is_isprint) {
            this.productName = JSON.parse(this.iSprintData.product_name);
            this.productName = this.productName?.en_US;

            this.weight = this.iSprintData?.size;
            this.isWeightEmpty = this.isEmpty(this.weight);
            if (this.isWeightEmpty) {
              this.weight = this.weight.split(' ');
              this.weight = this.weight[0];
              this.weight = parseInt(this.weight) / 1000;
            } else {
              this.weight = 0;
            }

          } else {
            this.productName = this.iSprintData.brand;
            this.weight = this.iSprintData?.size;
            this.isWeightEmpty = this.isEmpty(this.weight);
            if (this.isWeightEmpty) {
              this.weight = this.weight.split(' ');
              this.weight = this.weight[0];
              this.weight = parseInt(this.weight) / 1000;
            } else {
              this.weight = 0;
            }
          }

          this.productName = this.productName.toLowerCase();

          if (this.productName.startsWith('ensure')) {
            this.productType = 'ensure';
          } else if (this.productName.startsWith('pediasure')) {
            this.productType = 'pediasure';
          } else if (this.productName.startsWith('similac gold')) {
            this.productType = 'similac gold';
          } else if (this.productName.startsWith('similac mom')) {
            this.productType = 'similac mom';
          } else if (this.productName.startsWith('similac intelli-pro')) {
            this.productType = 'similac intelli-pro';
          } else if (this.productName.startsWith('similac gain')) {
            this.productType = 'similac gold';
          } else if (this.productName.startsWith('glucerna')) {
            this.productType = 'glucerna';
          }

          console.log('weight: ', this.weight);

          this.weight = JSON.stringify(this.weight);
          sessionStorage.setItem('productWeight', this.weight);

          console.log(this.iSprintData?.is_isprint);
          if (this.iSprintData?.is_isprint) {
            // console.log('image: ', this.image);
            let parsedPrimaryValue = this.parseFunction(
              this.iSprintData?.product_name
            );
            let prdname = parsedPrimaryValue;

            if (typeof parsedPrimaryValue == 'object')
              prdname = parsedPrimaryValue.en_US;
            else prdname = parsedPrimaryValue;
            let formData = {
              scan_code: scannedCode,
              mobile: mobile,
              cap_device_id: env.DEVICE_ID,
              customer_name: this.customerName,
              suspicious_count: this.suspiciousCount,
              product_name: prdname,
              product_image: this.iSprintData?.product_image,
              confidence: this.confidence,
              product_type: this.productType,
              child_count: '1',
              weight:
                this.weight == undefined || this.weight == null ? '0' : this.weight,
              //"weight": this.weight,
              custom_fields: [
                {
                  name: 'TransLat',
                  value: this.latitude,
                },
                {
                  name: 'TransLong',
                  value: this.longitude,
                },
                {
                  name: 'productName',
                  value: prdname,
                },
                {
                  name: 'category_code_trans',
                  value: this.categoryCode ? this.categoryCode : '',
                },
                {
                  name: 'sfproductcode',
                  value: this.sku,
                },
                {
                  name: 'productimage',
                  value: this.productImage,
                },
              ],
            };
            this.spinner.show();
            console.log(formData, 'form data');
            let isprintresp;
            this.apiService
              .postGpassEarnPoints(formData, token)
              .pipe(first())
              .subscribe(
                (res: any) => {
                  isprintresp = res;
                  this.spinner.hide();
                  // this.processingModal.show();
                  if (res['status'] == 'success') {
                    this.spinner.hide();
                    // this.processingModal.hide();
                    // this.congratsModalStepTwo.show()
                    // this.disabled = true;
                    console.log(res, 'resp on isprint earns points....');
                    this.isprintPoint = res?.result?.points;
                    // this.successScanModal.show();
                    this.router.navigate(['/carepoints']);

                    //Get Points API
                    // let mobile = sessionStorage.getItem('mobileNo');
                    // let token = sessionStorage.getItem('authToken');
                    // this.spinner.show();
                    // this.apiService
                    //   .getPoints(mobile, token)
                    //   .pipe(first())
                    //   .subscribe((res: any) => {
                    //     this.spinner.hide();
                    //     console.log(
                    //       res?.customer?.transactions?.transaction,
                    //       'points list'
                    //     );
                    //     this.pointsEarned =
                    //       res?.customer?.transactions?.transaction[0]?.points?.issued;
                    //    this.alreadyScanned.show();
                    //     this.router.navigate(['/home'])
                    //   });
                  } else {
                    console.log('subscribe else part in isprint');
                    // add transcation fail show the pop-up
                    this.alreadyScanned.show();
                    this.spinner.hide();
                  }
                },
                (err) => {
                  this.spinner.hide();
                  console.log(err);
                  this.alreadyScanned.show();

                  if (err.status === 420) {
                    this.exceedLimitModal.show();
                  }
                  //else {
                  //   this.alreadyScanned.show();
                  //   this.router.navigate(['/profile']);
                  // }
                  // this.disable = true
                 
        
                  this.bsService.notifyError(err, formData);
                  this.spinner.hide()
                  this.apiService.showErrorMsg('Something went wrong, please try again')
                  console.log(err, '=== get customer error =======')
                }
              );
          } else {
            this.spinner.show();

            //useful in future----------
            let gapsprodimg;
            this.apiService
              .getGpasProductImage(this.iSprintData?.sku)
              .subscribe((res: any) => {
                gapsprodimg = res;
                this.spinner.hide();
                console.log(res.data, 'getGpasProductImage');
                //console.log(this.latitude, this.longitude, this.confidence);
                this.prodImage = res.data[0]?.productimage;
                console.log(this.prodImage, 'this.prodImage ');

                let formData = {
                  scan_code: scannedCode,
                  mobile: mobile,
                  customer_name: this.customerName,
                  suspicious_count: this.suspiciousCount,
                  product_name:
                    this.iSprintData?.brand +
                    ' ' +
                    this.iSprintData?.flavor +
                    ' ' +
                    this.iSprintData?.size,
                  product_image: this.prodImage ? this.prodImage : 'null',
                  latitude: this.latitude,
                  longitude: this.longitude,
                  confidence: this.confidence,
                  product_type: this.productType,

                  child_count: '1',
                  weight:
                    this.weight == undefined || this.weight == null
                      ? '0'
                      : this.weight,
                  //"weight": this.weight,
                  custom_fields: [
                    {
                      name: 'productname',
                      value:
                        this.iSprintData?.brand +
                        ' ' +
                        this.iSprintData?.flavor +
                        ' ' +
                        this.iSprintData?.size,
                    },
                    {
                      name: 'categorycode',
                      value:
                        this.iSprintData?.brand +
                        ' ' +
                        this.iSprintData?.flavor +
                        ' ' +
                        this.iSprintData?.size,
                    },
                    {
                      name: 'category_code_trans',
                      value:
                        this.iSprintData?.brand 
                    },
                    {
                      name: 'sfproductcode',
                      value: this.sku,
                    },
                  ],
                };
                console.log(formData, 'gpas form data');
                this.spinner.show();
                let gpasresp;
                this.apiService
                  .postGpassEarnPoints(formData, token)
                  .pipe(first())
                  .subscribe(
                    (res: any) => {
                      gpasresp = res;
                      this.spinner.hide();
                      console.log('res: ', res);
                      // this.processingModal.show();

                      if (res['status'] == 'success') {
                        this.spinner.hide();
                        // this.processingModal.hide();
                        // this.congratsModalStepTwo.show();
                        // this.disabled = true;
                        // this.disable = true;
                        //this.gpasPoints =

                        // this.apiService.getSkuPoints(this.gpasSku).subscribe((res:any)=>{
                        //   console.log(res?.data[0]?.points, 'gpaspoints');
                        //   this.gpasPoints = res?.data[0]?.points;
                        // })
                        console.log(res, 'resp on GPAS earns points....');
                        // this.gpasSuccessMdl.show();
                        this.router.navigate(['/carepoints']);
                        //console.log(res);
                        //Get Points API
                        // let mobile = sessionStorage.getItem('mobileNo');
                        // let token = sessionStorage.getItem('authToken');
                        // this.spinner.show();
                        // this.apiService
                        //   .getPoints(mobile, token)
                        //   .pipe(first())
                        //   .subscribe((res: any) => {
                        //     this.spinner.hide();
                        //     console.log(
                        //       res?.customer?.transactions?.transaction,
                        //       'points list'
                        //     );
                        //     this.pointsEarned =
                        //       res?.customer?.transactions?.transaction[0]?.points?.issued;
                        //    // this.onClickSuccessScanModal();
                        //    this.router.navigate(['/home'])
                        //   });
                      } else {
                        // add transcation fail show the pop-up
                        this.alreadyScanned.show();
                        this.spinner.hide();
                      }
                    },
                    (err) => {
                      //alert(err.error.message);
                      // this.processingModal.hide();
                      this.spinner.hide();
                      this.alreadyScanned.show();
                      if (err.status == 420) {
                        this.exceedLimitModal.show();
                      }
                      //else {
                      //   alert(err.error.message);
                      //   this.router.navigate(['/profile']);
                      // }
                     
                      this.bsService.notifyError(err, formData);
                      this.spinner.hide()
                      this.apiService.showErrorMsg('Something went wrong, please try again')
                      console.log(err, '=== get customer error =======')
                    }
                  );
                // },
                //   (err) => {
                //     this.spinner.hide();
                //   }
                // );
              },err=>{
               
      
                this.bsService.notifyError(err);
                this.spinner.hide()
                this.apiService.showErrorMsg('Something went wrong, please try again')
                console.log(err, '=== get customer error =======')
              });
          }

        } else console.log('error in get customer')
      },err=>{

        this.bsService.notifyError(err);
        this.spinner.hide()
        this.apiService.showErrorMsg('Something went wrong, please try again')
        console.log(err, '=== get customer error =======')
        console.log(mobileNo, '=== get customer mobile =======');
        console.log(err?.error?.message, '=== get customer error message =======');
        console.log(err?.error?.code, '=== get customer error code =======');
      })

    this.iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));

    console.log(this.iSprintData.sku, 'isprint sku');

    //doubt -----
    // env.sfproductList.filter((data) => {
    //   if (data.sku == this.iSprintData.sku) {
    //     this.sfCode = data.sfcode;
    //     console.log(data, 'data===');
    //   }
    // });


  }
}
