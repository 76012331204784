import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { MustMatch } from 'src/app/_helpers/must-mathc.validator';
import { environment as env } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  otpForm: FormGroup;
  submittedOtp = false;
  mobileNumber: any;
  sessionId: any;
  registerFormData: any;
  addUserRegisterForm: any = {};
  scannedQRCode: any;
  categoryCode: any;
  checkQRCode2: any;
  productImage: any;
  showResendOtp: boolean = false;
  timeLeft: any;
  beginTimer: boolean = false;
  invalidOtp: boolean = false;
  otp: string;
  activeClassButton: boolean = false;
  showSignUp = true;
  showOtp = false;
  emailAlreadyPresent: boolean = false;
  @ViewChild('otpModal', { static: true }) otpModal: ModalDirective;
  @ViewChild('alreadyScanned', { static: true }) alreadyScanned: ModalDirective;
  @ViewChild('exceedLimitModal', { static: true })
  exceedLimitModal: ModalDirective;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  @ViewChild('alreadyRegistered', { static: true })
  alreadyRegistered: ModalDirective;
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: true,
    inputStyles: {
      width: '50px',
      height: '50px',
    },
    inputClass: true,
  };
  otpLength: any;
  iSprintData: any;
  sku: string;
  customerName: any;
  suspicious_count: any;
  confidence: any;
  latitude: string;
  longitude: string;
  weight: any;
  isprintPoints: any;
  prodImage: any;
  isWeightEmpty: any;
  selectedField: any;
  productName: any;
  productType: string;
  user: any;
  userPoints: any;
  suspiciousCount: number = 0;
  weightInUnits: any;
  weightFinal: any;
  refCode: any;
  readonly: boolean = false;
  withzeroMob: any;
  entermobNo: any;
  entermobNowithzero: any;
  storedMobNum: any;
  params: string;
  salesforceID: any;
  salesforce: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private bsService: BugsnagService
  ) {
    this.refCode = this.route.snapshot.queryParams.code;
    console.log(this.refCode, 'refcode-----');
    if (this.refCode) {
      this.readonly = true;
    } else {
      this.readonly = false;
    }
    console.log(this.readonly);

    this.getPosition();
  }

  handleLoginBtn() {
    this.router.navigate(['/login']);
  }

  onOtpChange(otp) {
    // this.otp = otp;
    this.invalidOtp = false;
    this.otp = otp;
    console.log('---', otp.length);
    console.log(this.otp, 'this.otp');
    this.otpLength = otp.length;
    if (otp.length == 6) {
      this.activeClassButton = true;
    } else {
      this.activeClassButton = false;
    }
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
  }
  onClickChangeNumber() {
    this.showOtp = false;
    this.showSignUp = true;
    this.submitted = false;
    this.registerForm.removeControl("recaptcha");
    this.registerForm.addControl("recaptcha", this.formBuilder.control('', [Validators.required]));
  }
  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.registerForm = this.formBuilder.group(
      {
        firstName: [
          '',
          [Validators.required, Validators.pattern("^[a-zA-Z -']+")],
        ],
        lastName: [
          '',
          [Validators.required, Validators.pattern("^[a-zA-Z -']+")],
        ],
        countryCode: [''],
        mobileNumber: [
          '',
          [
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(11),
          ],
        ],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
            ),
          ],
        ],
        // surName: ['', Validators.required],
        // addressLine1: ['', Validators.required],
        // addressLine2: ['', Validators.required],
        // addressLine3: ['', Validators.required],
        // postalCode: ['', Validators.required],
        // country: ['', Validators.required],
        selectFavouriteBrand: ['', Validators.required],
        referrerCode: [''],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern('^(?=.*[\\W_])(?=.*\\d).{8,}$'),
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.pattern('^(?=.*[\\W_])(?=.*\\d).{8,}$'),
          ],
        ],
        productUsage: ['', Validators.required],
        subscription: [false, Validators.requiredTrue],
        recaptcha: ['', Validators.required]
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
    this.otpForm = this.formBuilder.group({
      otp: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(10),
        ],
      ],
    });

    let scanCodeData = JSON.parse(sessionStorage.getItem('iSprintData'));
    if (scanCodeData != null) {
      let iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));
      this.iSprintData = iSprintData;
      this.categoryCode = iSprintData?.product?.categoryCode;
      console.log(this.categoryCode, 'iSprintData==========');
      this.scannedQRCode = sessionStorage.getItem('scanCode');
      this.checkQRCode2 = iSprintData?.uaid_info_type;
      this.productImage = iSprintData?.product_image;
      console.log(this.productImage, 'this.productImage--');
      let sku = sessionStorage.getItem('sku');
      this.sku = sku;
      console.log(sku, 'sku at pg load');
    }

    this.registerForm.patchValue({
      referrerCode: this.refCode ? this.refCode : '',
    });
  }

  checkSalesForce(ev) {
    console.log(ev.target.value, '000');
    let mobNo = ev.target.value;
    this.withzeroMob = ev.target.value;
    mobNo = mobNo.replace(/\b0+/g, '');
    this.entermobNo = mobNo;
    //this.entermobNo = parseInt(this.entermobNo)
    this.entermobNowithzero = ev.target.value.replace(/\b0+/g, '');
    this.storedMobNum = this.entermobNowithzero;
    sessionStorage.setItem('storedMobNum', this.storedMobNum);
    console.log(this.entermobNo, 'this.entermobNo');
    console.log(this.entermobNowithzero, 'this.entermobNowithzero');
    //   if (this.params == '11' || this.params == '12') {
    this.spinner.show();
    this.apiService
      .getSalesForceData("0" + mobNo)
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          let salesforceData = res['result'];
          if (salesforceData?.Id?.length > 0) this.salesforce = true
          console.log("salesforce: ", salesforceData);
          this.salesforceID = res['result'].Id
          let mobile = salesforceData?.Phone ? salesforceData?.Phone.replace(/\b0+/g, '') : ''
          this.registerForm.patchValue({
            mobileNumber: mobile,
            firstName: salesforceData ? salesforceData?.FirstName : '',
            email: salesforceData ? salesforceData?.Email : '',
            lastName: salesforceData ? salesforceData?.LastName : '',
          });
          if (salesforceData?.Phone) {
            //this.registerForm.controls["mobileNumber"].disable();
          }
          this.registerForm.controls['firstName'].enable();
          this.registerForm.controls['lastName'].enable();
          this.registerForm.controls['email'].enable();
          this.checkUser(mobNo);
        },
        (err) => {

          this.bsService.notifyError(err);
          this.spinner.hide();
          console.log(err, 'err')

          this.spinner.hide();
          console.log(err, 'err--');
          this.registerForm.controls['name'].enable();
          this.registerForm.controls['surname'].enable();
          this.registerForm.controls['email'].enable();
          this.checkUser(mobNo);
        }
      );
    // }
  }

  checkUser(mobNo) {
    this.spinner.show();
    let formData = {
      identifierType: 'MOBILE',
      identifierValue: mobNo,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      password: 'test123',
      confirmPassword: 'test123',
    };
    this.apiService
      .tokenGenerate(formData)
      .pipe(first()) //token generate api
      .subscribe((res) => {
        this.spinner.hide();
        if (res['status'].code == 200) {
          let sessionId = res['user'].sessionId;
          sessionStorage.setItem('sessionID', JSON.stringify(sessionId));
          // if (res['user'].userRegisteredForPassword == true) {
          //   // if user is present in CRM DB
          //   console.log('user already registered');
          //   this.router.navigate(['/login']);
          // }
        }
      }, err => {
        this.bsService.notifyError(err);
      });
  }

  getPosition() {
    let posresp;
    this.apiService.getPosition().then((pos) => {
      posresp = pos
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
    }, err => {


      this.bsService.notifyError(err);
      this.spinner.hide()
      this.apiService.showErrorMsg('Something went wrong, please try again')
      console.log(err, '=== get customer error =======')
    });
  }

  closeOtpModal() {
    this.otpModal.hide();
    this.beginTimer = false;
  }

  get fVal() {
    return this.registerForm.controls;
  }

  get OtpFval() {
    return this.otpForm.controls;
  }
  onRegisterFormSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      console.log(this.registerForm.value, 'inside invalid');
      console.log(this.registerForm.controls, 'controls');
      return;
    } else {
      if (this.registerForm.value.productUsage == 'true')
        this.registerForm.value.productUsage = Boolean(
          this.registerForm.value.productUsage
        );
      if (this.registerForm.value.productUsage == 'false')
        this.registerForm.value.productUsage = Boolean(
          this.registerForm.value.productUsage
        );

      console.log(this.registerForm);
      console.log(this.registerForm.invalid, 'this.registerForm.invalid');
      console.log(this.registerForm.valid, 'this.registerForm.valid');
      // if (this.registerForm.invalid) {
      //   console.log(this.registerForm.value, 'in invalid---')
      //   console.log(this.registerForm.controls, 'this.registerForm.controls in if---')
      //   return;
      // }
      if (
        this.registerForm.get('password').status == 'INVALID' ||
        this.registerForm.get('confirmPassword').status == 'INVALID' ||
        this.registerForm.get('email').status == 'INVALID' ||
        this.registerForm.get('firstName').status == 'INVALID' ||
        this.registerForm.get('lastName').status == 'INVALID' ||
        this.registerForm.get('mobileNumber').status == 'INVALID' ||
        this.registerForm.get('selectFavouriteBrand').status == 'INVALID' ||
        this.registerForm.get('confirmPassword').status == 'INVALID' ||
        this.registerForm.get('subscription').status == 'INVALID' ||
        this.registerForm.get('productUsage').status == 'INVALID'
      ) {
        console.log(this.registerForm.value, 'in invalid---');
        console.log(
          this.registerForm.controls,
          'this.registerForm.controls in if---'
        );
        return;
      } else {
        console.log('else---');
        console.log(
          this.registerForm.controls,
          'this.registerForm.controls in else---'
        );
        this.spinner.show();
        this.registerFormData = this.registerForm.value;
        // let mobileNumber  = this.mobileForm.value ? this.mobileForm.value.mobileNumber : '';
        // let password  = this.mobileForm.value ? this.mobileForm.value.newPassword : '';
        // let confirmPassword  = this.mobileForm.value ? this.mobileForm.value.confirmPassword : '';

        //---------on form submit first check in email already exsist-------------- mobileNumber
        let mobNo = '60' + this.registerFormData.mobileNumber;
        this.apiService
          .checkExistingEmail(
            encodeURIComponent(this.registerFormData.email),
            mobNo
          )
          .pipe(first())
          .subscribe(
            (res: any) => {
              this.spinner.hide();
              if (res.result.statusCode == 200) {
                this.emailAlreadyPresent = false;
                this.spinner.show();
                sessionStorage.setItem(
                  'mobileNo',
                  this.registerFormData.mobileNumber.replace(/\b0+/g, '')
                );
                this.mobileNumber = sessionStorage.getItem('mobileNo');
                let submitedData = {
                  identifierType: 'MOBILE',
                  identifierValue: this.mobileNumber,
                  deviceId: env.DEVICE_ID,
                  brand: env.BRAND,
                  password: this.registerFormData.password,
                  confirmPassword: this.registerFormData.confirmPassword,
                };
                let tokengenresp;
                this.apiService
                  .tokenGenerate(submitedData)
                  .pipe(first())
                  .subscribe(
                    (res) => {
                      tokengenresp = res;
                      this.spinner.hide();
                      if (res['status'].code == 200) {
                        // if (res['user'].userRegisteredForPassword == true) {
                        //   this.alreadyRegistered.show();
                        // } else {
                        this.sessionId = res['user'].sessionId;
                        let otpGenerateData = {
                          identifierType: 'MOBILE',
                          identifierValue: this.mobileNumber,
                          deviceId: env.DEVICE_ID,
                          brand: env.BRAND,
                          sessionId: this.sessionId,
                        };
                        let otpgen;
                        this.spinner.show();
                        this.apiService
                          .otpGenerate(otpGenerateData)
                          .pipe(first())
                          .subscribe(
                            (res) => {
                              otpgen = res;
                              this.spinner.hide();
                              if (res['status'].code == 200) {
                                // this.showOtpModal()
                                this.showOtp = true;
                                this.beginTimer = true;
                              } else if (res['status'].code == 401) {
                                this.apiService.logout();
                              } else {
                                this.apiService.showToaster(
                                  res['status'].message
                                );
                              }
                            },
                            (err) => {

                              this.bsService.notifyError(err, otpGenerateData);
                              this.spinner.hide()
                              this.apiService.showErrorMsg('Something went wrong, please try again')
                              console.log(err, '=== get customer error =======')
                            }
                          );
                        // }
                      } else if (res['status'].code == 401) {
                        this.apiService.logout();
                      } else {
                        // this.apiService.showToaster(res['status'].message);
                      }
                    },
                    (err) => {

                      this.bsService.notifyError(err, submitedData);
                      this.spinner.hide()
                      this.apiService.showErrorMsg('Something went wrong, please try again')
                      console.log(err, '=== get customer error =======')
                    }
                  );
              }
            },
            (err) => {
              console.log('inerr--');
              this.emailAlreadyPresent = true;
              this.spinner.hide();
              this.bsService.notifyError(err);
            }
          );
      }
    }
  }
  onSubmitOtpForm() {
    this.submittedOtp = true;
    let otpFormValue = this.otpForm.value;
    console.log(otpFormValue.otp, 'this.otpForm.value---');
    // if (this.otpForm.invalid) {
    //   this.spinner.hide();
    //   return;
    // }
    if (this.otpLength != 6) {
      return;
    } else {
      let otpSubmitData = {
        identifierType: 'MOBILE',
        identifierValue: this.mobileNumber,
        deviceId: env.DEVICE_ID,
        brand: env.BRAND,
        sessionId: this.sessionId,
        // "otp":otpFormValue.otp,
        otp: this.otp,
      };
      let otpvalresp;
      this.spinner.show();
      this.apiService
        .otpValidate(otpSubmitData)
        .pipe(first())
        .subscribe(
          (res) => {
            otpvalresp = res;
            this.spinner.hide();
            if (res['status'].code == 200) {
              sessionStorage.setItem('authToken', res['auth'].token);
              sessionStorage.setItem('authKey', res['auth'].key);
              let getauthtoken = sessionStorage.getItem('authToken');
              let gethauthkey = sessionStorage.getItem('authKey');
              let getMobile = sessionStorage.getItem('mobileNo');
              // OTP Form Data
              this.spinner.show();
              let OTPformData = {
                mobile: this.mobileNumber,
                otp_token: getauthtoken,
                otp_key: gethauthkey,
              };
              this.apiService.postAuthToken(OTPformData).subscribe((res) => {
                this.spinner.hide();
                console.log(res, 'otp form data submit');
              }, err => {
                this.bsService.notifyError(err, OTPformData);
              });
              let token = sessionStorage.getItem('authToken');
              // this.otpModal.hide();

              this.apiService.getPosition().then((pos) => {
                let lat = pos.lat;
                let lang = pos.lng;
                let latitude = lat.toString();
                let langitude = lang.toString();

                let filledFormValues = this.getuserForm(
                  this.registerFormData,
                  latitude,
                  langitude
                );
                console.log(this.mobileNumber, 'mobile--');
                console.log(filledFormValues, 'filledFormValues---');
                this.spinner.show();
                let addcustresp;
                this.apiService
                  .addCapCustomer(filledFormValues, this.mobileNumber, token)
                  .pipe(first())
                  .subscribe(
                    (res) => {
                      addcustresp = res;
                      this.spinner.hide();

                      if (res['status'].code == 200) {
                        sessionStorage.setItem(
                          'mobileNo',
                          this.registerFormData.mobileNumber.replace(/\b0+/g, '')
                        );

                        sessionStorage.setItem(
                          'userPassword',
                          this.registerFormData.confirmPassword
                        );
                        if (this.checkQRCode2 == 1 || this.checkQRCode2 == '') {
                          let data = {
                            data: 'login',
                          };
                          this.router.navigate(['/carepoints']);
                          console.log('true');
                        }
                        //--- add Transaction----
                        if (
                          // this.checkQRCode2 == 2 ||
                          // this.checkQRCode2 != undefined
                          this.checkQRCode2 == 2 &&
                          this.iSprintData?.identifier == undefined
                        ) {
                          this.onClickCancelModalone(this.scannedQRCode);

                          // let productName = sessionStorage.getItem('productName');
                          // let sku = sessionStorage.getItem('sku');
                          // let addformData = {
                          //   scan_code: this.scannedQRCode,
                          //   mobile: this.mobileNumber,
                          //   cap_device_id: env.DEVICE_ID,
                          //   custom_fields: [
                          //     {
                          //       name: 'translat',
                          //       value: latitude,
                          //     },
                          //     {
                          //       name: 'translong',
                          //       value: langitude,
                          //     },
                          //     {
                          //       name: 'productname',
                          //       value: productName,
                          //     },
                          //     {
                          //       name: 'category_code_trans',
                          //       value: this.categoryCode
                          //         ? this.categoryCode
                          //         : '',
                          //     },
                          //     {
                          //       name: 'sfproductcode',
                          //       value: sku,
                          //     },
                          //     {
                          //       name: 'productimage',
                          //       value: this.productImage,
                          //     },
                          //   ],
                          // };
                          // if (this.checkQRCode2 == 2) {
                          //   this.spinner.show();
                          //   this.apiService
                          //     .addTransaction(addformData, token)
                          //     .pipe(first())
                          //     .subscribe(
                          //       (res) => {
                          //         this.spinner.hide();

                          //         if (res['status'] == 'success') {
                          //           console.log('in if-----');
                          //           this.closeOtpModal();
                          //           this.router.navigate(['/carepoints']);
                          //           //Get Points API
                          //           let mobile =
                          //             sessionStorage.getItem('mobileNo');
                          //           let token =
                          //             sessionStorage.getItem('authToken');
                          //         } else {
                          //           console.log('in else---');
                          //           this.closeOtpModal();
                          //         }
                          //       },
                          //       (err) => {
                          //         this.spinner.hide();

                          //         this.closeOtpModal();
                          //         this.alreadyScanned.show();
                          //         // alert('This QR code has been scanned');
                          //         console.log(
                          //           err,
                          //           '=== get customer error ======='
                          //         );
                          //       }
                          //     );
                          // }
                        } else {
                          console.log(res['status'].message);

                          this.router.navigate(['/carepoints']);
                        }
                      }
                    },
                    (err) => {


                      this.bsService.notifyError(err, filledFormValues);
                      this.spinner.hide()
                      this.apiService.showErrorMsg('Something went wrong, please try again')
                      console.log(err, '=== get customer error =======')
                    }
                  );
              }, err => {
                this.bsService.notifyError(err);
              }); // get position
            } else if (res['status'].code == 401) {
              this.apiService.logout();
            } else if (
              res['status'].code == 1506 ||
              res['status'].message == 'Invalid OTP.'
            ) {
              this.invalidOtp = true;
            } else {
              this.invalidOtp = true;
            }
          },
          (err) => {


            this.bsService.notifyError(err, otpSubmitData);
            this.spinner.hide()
            this.apiService.showErrorMsg('Something went wrong, please try again')
            console.log(err, '=== get customer error =======')
          }
        );
    }
  }

  getuserForm(userData, latitude, langitude) {
    // let Signupmode = sessionStorage.getItem('SignupMode')
    console.log(userData, 'userdata register data----');
    return (this.addUserRegisterForm = {
      root: {
        customer: [
          {
            mobile: userData.mobileNumber,
            email: userData.email,
            firstname: userData.firstName,
            lastname: userData.lastName,
            external_id: this.salesforce ? this.salesforceID : 'null',
            //referral_code: userData.referrerCode,
            custom_fields: {
              field: [
                {
                  name: 'categorycode',
                  value: this.categoryCode ? this.categoryCode : '',
                },
                {
                  name: 'reglat',
                  value: latitude,
                },
                {
                  name: 'reglong',
                  value: langitude,
                },
                {
                  name: 'past_usage_product',
                  value: userData.productUsage,
                },
                // {
                //   "name":"referral_code",
                //   "value":userData.referrerCode
                // }
                // {
                //   "name": "favourite_brand",
                //   "value": userData.selectFavouriteBrand,
                // }
              ],
            },
            extended_fields: {
              field: [
                {
                  name: 'preferred_brand',
                  value: userData.selectFavouriteBrand,
                },
              ],
            },
            subscriptions: {
              subscription: [
                {
                  priority: 'BULK',
                  scope: 'all',
                  is_subscribed: userData.subscription == true ? '1' : '0',
                  channel: 'email',
                },
                {
                  priority: 'BULK',
                  scope: 'all',
                  is_subscribed: userData.subscription == true ? '1' : '0',
                  channel: 'sms',
                },
              ],
            },
          },
        ],
      },
    });
  }

  closeScannedModal() {
    this.alreadyScanned.hide();
    this.router.navigate(['/carepoints']);
  }

  handleEvent(event) {
    console.log(event.left, 'event');
    this.timeLeft = event.left;
    if (this.timeLeft == 0) {
      this.showResendOtp = true;
    }
  }

  otpInput(ev) {
    if (ev.keyCode == 8) {
      this.invalidOtp = false;
    }
  }

  showOtpModal() {
    this.otpModal.show();
    this.beginTimer = true;
  }

  resendOTP() {
    this.spinner.show();
    this.ngOtpInput.setValue('');
    this.showResendOtp = false;
    let getMobile = sessionStorage.getItem('mobileNo');
    let sessionIdValue = JSON.parse(sessionStorage.getItem('sessionID'));
    let otpGenerateData = {
      mobile: getMobile,
      deviceId: env.DEVICE_ID,
      brand: env.BRAND,
      sessionId: sessionIdValue,
    };
    let otpgen;
    this.apiService
      .otpGenerate(otpGenerateData)
      .pipe(first())
      .subscribe(
        (res) => {
          otpgen = res;
          this.spinner.hide();
          if (res['status'].code == 200) {
          } else if (res['status'].code == 401) {
            this.apiService.logout();
          } else {
            this.apiService.showToaster(res['status'].message);
          }
        },
        (err) => {

          this.bsService.notifyError(err, otpGenerateData);
          this.spinner.hide()
          this.apiService.showErrorMsg('Something went wrong, please try again')
          console.log(err, '=== get customer error =======')
        }
      );
  }

  onlyCharacters(ev) {
    let onlynum = /^[a-zA-Z .]*$/;

    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  onlyNumbers(ev) {
    let onlynum = /^[0-9]*$/;

    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  emailInput(ev) {
    if (ev.keyCode == 8) {
      this.emailAlreadyPresent = false;
    }
  }

  closeRegisteredModal() {
    this.alreadyRegistered.hide();
    this.router.navigate(['/login']);
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  isEmpty(weight) {
    if (weight == '' || weight == undefined || weight == null) {
      return false;
    } else {
      return true;
    }
  }

  getWeightLimit(weight) {
    for (let i = 0; i < weight.length; i++) {
      let flag: Boolean = isNaN(weight[i]);
      if (!flag) {
        weight = weight.split(weight[i - 1]);
      }
    }
    return weight;
  }

  onClickCancelModalone(scannedCode) {
    // this.congratsModalStepOne.hide();
    // this.processingModal.show();

    let mobileNo = sessionStorage.getItem('mobileNo');
    let tokenAuth = sessionStorage.getItem('authToken');
    let getcustresp;
    this.apiService.getCapCustomer(mobileNo, tokenAuth).subscribe(
      (res: any) => {
        getcustresp = res;
        if (res['status'].code == 200) {
          console.log(res, 'res---');
          console.log(res['customers']['customer'][0].user_id, 'used id---');
          sessionStorage.setItem(
            'abbottUserId',
            res['customers']['customer'][0].user_id
          );
          this.user = res['customers']['customer'][0];
          this.userPoints = res['customers']['customer'][0]?.loyalty_points
            ? res['customers']['customer'][0]?.loyalty_points
            : '0';
          console.log(this.userPoints, 'this.userPoints');
          let lifetimepoints =
            res['customers']['customer'][0].lifetime_purchases;
          console.log(this.userPoints, 'this.userPoints');
          console.log(lifetimepoints, 'lifetimepoints');
          this.customerName = this.user.firstname + ' ' + this.user.lastname;
          console.log(this.customerName);
          this.user.custom_fields.field.forEach((element) => {
            if (element.name === 'suspicious_count') {
              this.suspiciousCount = parseInt(element.value);
            }
          });

          // Isprint and Gpass codes
          let mobile = sessionStorage.getItem('mobileNo');
          let scanCode = sessionStorage.getItem('scanCode');
          let token = sessionStorage.getItem('authToken');

          if (this.iSprintData.is_isprint) {
            this.productName = JSON.parse(this.iSprintData.product_name);
            this.productName = this.productName?.en_US;

            this.weight = this.iSprintData?.size;
            this.isWeightEmpty = this.isEmpty(this.weight);
            if (this.isWeightEmpty) {
              this.weight = this.weight.split(' ');
              this.weight = this.weight[0];
              this.weight = parseInt(this.weight) / 1000;
            } else {
              this.weight = 0;
            }
          } else {
            this.productName = this.iSprintData.brand;
            this.weight = this.iSprintData?.size;
            this.isWeightEmpty = this.isEmpty(this.weight);
            if (this.isWeightEmpty) {
              this.weight = this.weight.split(' ');
              this.weight = this.weight[0];
              this.weight = parseInt(this.weight) / 1000;
            } else {
              this.weight = 0;
            }
          }

          this.productName = this.productName.toLowerCase();

          if (this.productName.startsWith('ensure')) {
            this.productType = 'ensure';
          } else if (this.productName.startsWith('pediasure')) {
            this.productType = 'pediasure';
          } else if (this.productName.startsWith('similac gold')) {
            this.productType = 'similac gold';
          } else if (this.productName.startsWith('similac mom')) {
            this.productType = 'similac mom';
          } else if (this.productName.startsWith('similac intelli-pro')) {
            this.productType = 'similac intelli-pro';
          } else if (this.productName.startsWith('similac gain')) {
            this.productType = 'similac gold';
          } else if (this.productName.startsWith('glucerna')) {
            this.productType = 'glucerna';
          }

          console.log('weight: ', this.weight);

          this.weight = JSON.stringify(this.weight);
          sessionStorage.setItem('productWeight', this.weight);

          console.log(this.iSprintData?.is_isprint);

          if (this.iSprintData?.is_isprint) {
            // console.log('image: ', this.image);
            let parsedPrimaryValue = this.parseFunction(
              this.iSprintData?.product_name
            );
            let prdname = parsedPrimaryValue;

            if (typeof parsedPrimaryValue == 'object')
              prdname = parsedPrimaryValue.en_US;
            else prdname = parsedPrimaryValue;
            let formData = {
              scan_code: scannedCode,
              mobile: mobile,
              cap_device_id: env.DEVICE_ID,
              customer_name: this.customerName,
              suspicious_count: this.suspicious_count,
              product_name: prdname,
              product_image: this.iSprintData?.product_image,
              confidence: this.confidence,
              product_type: this.productType,
              child_count: '1',
              weight:
                this.weight == undefined || this.weight == null
                  ? '0'
                  : this.weight,
              //"weight": this.weight,
              custom_fields: [
                {
                  name: 'TransLat',
                  value: this.latitude,
                },
                {
                  name: 'TransLong',
                  value: this.longitude,
                },
                {
                  name: 'productName',
                  value: prdname,
                },
                {
                  name: 'category_code_trans',
                  value: this.categoryCode ? this.categoryCode : '',
                },
                {
                  name: 'sfproductcode',
                  value: this.sku,
                },
                {
                  name: 'productimage',
                  value: this.productImage,
                },
              ],
            };
            this.spinner.show();
            console.log(formData, 'form data');
            let isprintresp;
            this.apiService
              .postGpassEarnPoints(formData, token)
              .pipe(first())
              .subscribe(
                (res: any) => {
                  isprintresp = res;
                  this.spinner.hide();
                  // this.processingModal.show();
                  if (res['status'] == 'success') {
                    this.spinner.hide();
                    // this.processingModal.hide();
                    // this.congratsModalStepTwo.show()
                    // this.disabled = true;
                    console.log(res, 'resp on isprint earns points....');
                    this.isprintPoints = res?.result?.points;
                    // this.successScanModal.show();
                    this.router.navigate(['/carepoints']);

                    //Get Points API
                    // let mobile = sessionStorage.getItem('mobileNo');
                    // let token = sessionStorage.getItem('authToken');
                    // this.spinner.show();
                    // this.apiService
                    //   .getPoints(mobile, token)
                    //   .pipe(first())
                    //   .subscribe((res: any) => {
                    //     this.spinner.hide();
                    //     console.log(
                    //       res?.customer?.transactions?.transaction,
                    //       'points list'
                    //     );
                    //     this.pointsEarned =
                    //       res?.customer?.transactions?.transaction[0]?.points?.issued;
                    //    this.alreadyScanned.show();
                    //     this.router.navigate(['/home'])
                    //   });
                  } else {
                    console.log('subscribe else part in isprint');
                    // add transcation fail show the pop-up
                    this.alreadyScanned.show();
                    this.spinner.hide();
                  }
                },
                (err) => {
                  this.spinner.hide();
                  console.log(err);
                  this.alreadyScanned.show();

                  if (err.status === 420) {
                    this.exceedLimitModal.show();
                  }
                  //else {
                  //   this.alreadyScanned.show();
                  //   this.router.navigate(['/profile']);
                  // }
                  // this.disable = true
                  this.bsService.notifyError(err, formData);
                  this.spinner.hide()
                  this.apiService.showErrorMsg('Something went wrong, please try again')
                  console.log(err, '=== get customer error =======')
                }
              );
          } else {
            this.spinner.show();

            //useful in future----------
            let gapsprodimg
            this.apiService
              .getGpasProductImage(this.iSprintData?.sku)
              .subscribe((res: any) => {
                gapsprodimg = res;
                this.spinner.hide();
                console.log(res.data, 'getGpasProductImage');
                //console.log(this.latitude, this.longitude, this.confidence);
                this.prodImage = res.data[0]?.productimage;
                console.log(this.prodImage, 'this.prodImage');

                let formData = {
                  scan_code: scannedCode,
                  mobile: mobile,
                  customer_name: this.customerName,
                  suspicious_count: this.suspicious_count,
                  product_name:
                    this.iSprintData?.brand +
                    ' ' +
                    this.iSprintData?.flavor +
                    ' ' +
                    this.iSprintData?.size,
                  product_image: this.prodImage ? this.prodImage : 'null',
                  latitude: this.latitude,
                  longitude: this.longitude,
                  confidence: this.confidence,
                  product_type: this.productType,

                  child_count: '1',
                  weight:
                    this.weight == undefined || this.weight == null
                      ? '0'
                      : this.weight,
                  //"weight": this.weight,
                  custom_fields: [
                    {
                      name: 'productname',
                      value:
                        this.iSprintData?.brand +
                        ' ' +
                        this.iSprintData?.flavor +
                        ' ' +
                        this.iSprintData?.size,
                    },
                    {
                      name: 'categorycode',
                      value:
                        this.iSprintData?.brand +
                        ' ' +
                        this.iSprintData?.flavor +
                        ' ' +
                        this.iSprintData?.size,
                    },
                    {
                      name: 'sfproductcode',
                      value: this.sku,
                    },
                  ],
                };
                console.log(formData, 'gpas form data');
                this.spinner.show();
                let gepasresp;
                this.apiService
                  .postGpassEarnPoints(formData, token)
                  .pipe(first())
                  .subscribe(
                    (res: any) => {
                      gepasresp = res;
                      this.spinner.hide();
                      console.log('res: ', res);
                      // this.processingModal.show();

                      if (res['status'] == 'success') {
                        this.spinner.hide();
                        // this.processingModal.hide();
                        // this.congratsModalStepTwo.show();
                        // this.disabled = true;
                        // this.disable = true;
                        //this.gpasPoints =

                        // this.apiService.getSkuPoints(this.gpasSku).subscribe((res:any)=>{
                        //   console.log(res?.data[0]?.points, 'gpaspoints');
                        //   this.gpasPoints = res?.data[0]?.points;
                        // })
                        console.log(res, 'resp on GPAS earns points....');
                        // this.gpasSuccessMdl.show();
                        this.router.navigate(['/carepoints']);
                        //console.log(res);
                        //Get Points API
                        // let mobile = sessionStorage.getItem('mobileNo');
                        // let token = sessionStorage.getItem('authToken');
                        // this.spinner.show();
                        // this.apiService
                        //   .getPoints(mobile, token)
                        //   .pipe(first())
                        //   .subscribe((res: any) => {
                        //     this.spinner.hide();
                        //     console.log(
                        //       res?.customer?.transactions?.transaction,
                        //       'points list'
                        //     );
                        //     this.pointsEarned =
                        //       res?.customer?.transactions?.transaction[0]?.points?.issued;
                        //    // this.onClickSuccessScanModal();
                        //    this.router.navigate(['/home'])
                        //   });
                      } else {
                        // add transcation fail show the pop-up
                        this.alreadyScanned.show();
                        this.spinner.hide();
                      }
                    },
                    (err) => {
                      //alert(err.error.message);
                      // this.processingModal.hide();
                      this.spinner.hide();
                      this.alreadyScanned.show();
                      if (err.status == 420) {
                        this.exceedLimitModal.show();
                      }
                      //else {
                      //   alert(err.error.message);
                      //   this.router.navigate(['/profile']);
                      // }

                      this.bsService.notifyError(err), formData;
                      this.spinner.hide()
                      this.apiService.showErrorMsg('Something went wrong, please try again')
                      console.log(err, '=== get customer error =======')
                    }
                  );
                // },
                //   (err) => {
                //     this.spinner.hide();
                //   }
                // );
              }, err => {

                this.bsService.notifyError(err);
                this.spinner.hide()
                this.apiService.showErrorMsg('Something went wrong, please try again')
                console.log(err, '=== get customer error =======')
              });
          }
        } else {
          console.log('error in get customer');
        }
      }, (err) => {
        this.bsService.notifyError(err, getcustresp);
        this.spinner.hide()
        this.apiService.showErrorMsg('Something went wrong, please try again')
        console.log(err, '=== get customer error =======')
        console.log(mobileNo, '=== get customer mobile =======');
        console.log(err?.error?.message, '=== get customer error message =======');
        console.log(err?.error?.code, '=== get customer error code =======');
      }

    );

    this.iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));

    console.log(this.iSprintData.sku, 'isprint sku');

    //doubt -----
    // env.sfproductList.filter((data) => {
    //   if (data.sku == this.iSprintData.sku) {
    //     this.sfCode = data.sfcode;
    //     console.log(data, 'data===');
    //   }
    // });
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  errored(ev) {
    console.warn(`reCAPTCHA error encountered`);
  }

  getSiteKey() {
    return env.SITE_KEY;
  }
}
