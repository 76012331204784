<app-header-logged-out></app-header-logged-out>
<div class="banner">
  <img
    src="../../../assets/images/share_care_world_banner.png"
    alt="share care world"
  />
</div>
<div class="signUp">
  <h5>
    JOIN NOW AS A MEMBER AND START EARNING YOUR CAREPOINTS FOR FABULOUS REWARDS!
  </h5>
  <a href="javascript:;" class="whiteBtn" routerLink="/register">sign up</a>
</div>
<div class="login">
  <div class="text-center">
    <img src="../../../assets/images/welcome_back.png" alt="welcome back" />
  </div>
  <form [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()">
    <div class="form-group">
      <label>Mobile Number</label>
      <div class="row">
        <div class="col-3 pr-1">
          <input
            type="text"
            class="countryCodeAlign form-control"
            formControlName="countryCode"
            placeholder="+60"
            disabled
          />
        </div>
        <div class="col-9 pl-0">
          <input
            type="text"
            class="form-control"
            formControlName="mobile"
            [ngClass]="{ 'is-invalid': submitted && fVal.mobile.errors }"
            (keypress)="onlyNumbers($event)"
            maxlength="10"
            minlength="9"
          />
          <div *ngIf="submitted && fVal.mobile.errors" class="invalid-feedback">
            <div *ngIf="fVal.mobile.errors.required">Enter mobile</div>
            <div *ngIf="fVal.mobile.errors.minlength">
              Mobile number must be atleast 9 digits
            </div>
            <div *ngIf="fVal.mobile.errors.maxlength">
              Mobile number must be atmost 10 digits
            </div>
          </div>
          <p class="contentDarkBlue mt-2">
            Please enter full handphone number beginning with 1. Eg: Handphone
            number 012-3899000. Key in 123899000.
          </p>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Password</label>
      <input
        type="password"
        class="form-control"
        formControlName="password"
        [ngClass]="{ 'is-invalid': submitted && fVal.password.errors }"
        (keydown)="passwordInput($event)"
      />
      <div *ngIf="submitted && fVal.password.errors" class="invalid-feedback">
        <div *ngIf="fVal.password.errors.required">Enter Password</div>
        <div
          *ngIf="fVal.password.errors.password || fVal.password.errors.pattern"
        >
          Password must have minimum 8 charecters, contain at least 1 numeric
          charecter and 1 special charecter.
        </div>
      </div>
      <div *ngIf="submitted && notRegistered" class="invalidText">
        User is not registered. Please register the user
      </div>
      <div *ngIf="submitted && passwordError" class="invalidText">
        Password does not match
      </div>
    </div>
    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        formControlName="remember_me"
      />
      <label class="form-check-label">Keep me signed in</label>
    </div>
    <div class="form-group">
      <re-captcha
        formControlName="recaptcha"
        (resolved)="resolved($event)"
        (error)="errored($event)"
        siteKey="{{getSiteKey()}}"
        [ngClass]="{ 'is-invalid': submitted && fVal.recaptcha.errors }"
        required
      >
      </re-captcha>
      <div
        *ngIf="submitted && fVal.recaptcha.errors"
        class="invalid-feedback reCaptchaError"
      >
        <div *ngIf="fVal.recaptcha.errors.required">Recaptcha is required</div>
      </div>
    </div>
    <button
      type="submit"
      class="blueBtn"
      [ngClass]="{ isValidSubmit: loginForm.valid }"
    >
      sign in
    </button>
  </form>
  <a href="javascript:;" class="link" routerLink="/forgot-password"
    >Forgot your password</a
  >
  <p class="termsInfo">
    <strong>For inquiries & more, please call</strong><br />
    Abbott Careline  <a href="tel:1800-88-6233"> 1800-88-6233</a>, from 9am-6pm (Mondays to Fridays)
  </p>
  <app-version></app-version>
</div>
<app-footer></app-footer>
<div
  bsModal
  #alreadyScanned="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeScannedModal()"
        >
          x
        </button>
        <p class="text-center contactInfo">
          <strong>Oops! This code has already been scanned. </strong> <br />If
          you think this is an error, please call
          <a href="tel:1800-88-6233"> 1800-88-6233</a> or email us at
          <a href="mailto:m.bluth@example.com">wecare@abbott.com</a>
        </p>

        <div class="text-center">
          <button
            type="submit"
            class="blueBtn text-white mt-3"
            (click)="closeScannedModal()"
          >
            ok
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #loginLanding="bs-modal"
  class="modal fade login_landing"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div>
        <button
          type="button"
          class="close mr-2"
          aria-label="Close"
          (click)="closeloginLandingModal()"
        >
          x
        </button>
      </div>

      <div class="modal-body">
        <!-- <button type="button" class="close" aria-label="Close" (click)="closeloginLandingModal()">
                    x
                </button> -->
        <!-- <p class="text-center">Sign up or login to capture your CarePoints.</p> -->
        <p class="text-center">Login to your Abbott Share & Care World account to get your CarePoints for this product</p>
      </div>
    </div>
  </div>
</div>


<div
  bsModal
  #loginPopup="bs-modal"
  class="modal fade login_landing"
  tabindex="-1"
  role="dialog"
  [config]="{backdrop: 'static'}"
    aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm loginPopup">
    <div class="modal-content">
      <div>
        <button
          type="button"
          class="close mr-2"
          aria-label="Close"
          (click)="closeloginPopup()"
        >
          x
        </button>
      </div>
      <div class="modal-body">
        <!-- <button type="button" class="close" aria-label="Close" (click)="closeloginLandingModal()">
                    x
                </button> -->
        <!-- <p class="text-center">Sign up or login to capture your CarePoints.</p> -->
       <img src="../../../../assets/images/login_popup.png" alt="loginpopup" />
      </div>
    </div>
  </div>
</div>
<!-- <app-bottom-nav></app-bottom-nav> -->
