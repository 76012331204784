export const environment = {
  production: false,
  // API_URl: "https://abbott-ph-api-dev.spurtreetech.com/api"
  AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  SALESFORCE_BASE_URL: ' https://abbott-ph-api-dev.sttarter.com/api/v1',
  CMS_URL: 'https://api.sttarter.com/contentsystem/d3eaf7a8067373ea08c47134748d7abf',
  API_URl_TRACEBILITY: "https://abbott-ph-api-dev.sttarter.com/api",
  IMAGE_UPLOAD: "https://apac-apigateway.capillarytech.com/mobile/v2/",
  EMAIL_CHECK_API: "https://abbott-ph-api-dev.sttarter.com/api/v1",
  // BRAND: 'ABBOTTDEMO',
  BRAND: 'ABBOTTALLDEMO',
  DEVICE_ID: '123454321',
  IDENTIFIER_TYPE: "MOBILE",
  STTARTER_TERMS_CONDITION_ABOUT_FAQ: "https://api.sttarter.com/contentsystem/0a4d50320b95bface947ebed669a494a",
  STTARTER_TERMS_FAQ: "https://api.sttarter.com/contentsystem/62b8b17263017ed475b3bfe42ec6a2b2",
  BUZZEEBEE_URL: "https://stg-web-abbott.azurewebsites.net/landing",

  NUTRITION_CUST_ADD_URL: 'https://cap-intouch-in.sttarter.com',
  NUTRITION_TILL_ID: 'nutrition',
  LICENSEKEY:'AXUidgHLI6D8CuynbiLcax8ub58lCtU2p0MDtUxhfvW0FwhlcEyKLBpV5/mIVDmc33CNdCp3ociXUiAydDohN5Ixa72vQGUGhEdvTRJ3xPAEbmmHtFAj4gtazHFPCoARbCjxudkiKSQSM+K17wn9/oNwFbM9be+LwCH41LZSFoowTUNYfkRtU/hxUTl6Tr9cjUULpSd0Yiz6NafaakX0eYhj1aK7Y+PlkmN4G7xQAiWhc/IbRGSY0uxrXdmxLLqh+RF/vX0vxQQQJ6hBnHbIv/pA1Hs7FF57BEDi7gpvHjTXV1h73goFjXpqDUFqN8iTP21BeBlpHI5zQLMIWgZtL1Ygjru/ZmWNnGHh+4B0F4mYW97C2F//j0Ev1bjJKD8RsnJsGQor6OcxSjDUkkpyaTxsFgzbUUAJC2GGoElKaX4vf0l73m61MgRwnpWBLfvulBtWTCB0AUEPV+Dmxj8ORWsnZnyHVBgUt1CIAXZE7ZylAoIE8EMWn2fUef0/OENkkdh6ngX2TNlpfZMGT0DUxUCg/vbfm3XHRQrZC4sGvKYevdol7/QfsfEbHvJup+IOBZ3Q/6vwtnLe9XwMBAsR+tpaGdZX7JJFkd30eRE0K7TdKCpqLzATW+7FR6RoGTqGJfLwJWaE8G/Jbb/4HPpviTSRjAPpEYlzz+VyDr2UrCUb2X54rzBnSVqSBTqK6AKcHVAVplKyWBile8QBi6U5owGXgGWBVaA/lPCKgdFLTeQxSgZ42VaySWaPumQBqHlP21K1QiHzAVw07jYP2QgArsPxJLJug8qCwYE0SwtIU0eZ3bM5m91K6YNm/cN4feRCbDYYaMinxx3aY46CKcARMJOHAQSMI3wcCURka84YRBw4QwdT4qav5IgcwEtF2KnmOzNS+6mVOFGNmUPM7osWooIZPGYzHcwlE1C2P7gn430BoqeikTI/UJ96/hS4VrWateekRlzChoQgGYe5nh/DEJ5FrRSIBx3QQbN8Qs8w0HRylxS0JnzfvCaXQOcag1o02R6DFHllUU107u/rkrJst2xRaVlm5N7Kh8YIJEjfymdZCOk4msiG7YS+CQVYWbMB3FodtUAPc+ZIADxsjcOiDMzExTI+AhtArh6iJWDQURUoBbL8hXeC74mb7BB1bgiNSjLWT629yKvoxNfgy5q1bA==',
  auth_key: "WLQ7eRcvLNSb0Z3hSAYIswPuf",
  auth_secret: "FfuXJFW5nW7zovCVVnDCmLF2TV7sUd",
  webqloURL: 'https://pediasure-growth-staging.uidhosting.com',
  TILL_ID: "abbottdemomalaysia",
  webQlo_api_key: "kFhq5xcXzsHJrqMoptDdqsfNt",
  COUNTRY_CODE: 'MY',
  LANGUAGE: 'en_US',
  REWARD_ID1: '1707',
  REWARD_ID2: '1706',
  webQlo_api_secret: "gkKcn642fEWggagJBjulbHjmjz3VED18RSYUDgnD",
  WRAPPER_API: "https://abbott-ph-api-dev.sttarter.com/api/v1",
  SITE_KEY: "6LcV2mQbAAAAALcsfVCxf7zChESp1v-1WOT3JOFB",
  bugsnag_version: '12072023',
  MOBILE_APP_KEY: "85a4447a3b438fbb6e33e5c0428460d3"
};