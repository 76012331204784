<app-header-primary></app-header-primary>
<div class="blueBg">
  <div class="carePoints">
    <div class="headerImg">
      <img
        src="../../../assets/images/my_collected_carepoints.png"
        alt="my collected care ponits"
      />
    </div>
    <div class="pointsOut">
      <h1>{{ userPoints }}</h1>
      <h4>carepoints</h4>
      <ng-container *ngIf="nearestListReward != undefined">
        <div routerLink="/redeem-rewards">
          <i class="giftImg">
            <img [src]="nearestListReward?.imageUrl" alt="gift image" />
          </i>
          <p class="primaryForeColor">
            Here’s a suggestion what you can redeem:
          </p>
          <p class="whiteForeColor text-uppercase">
            {{ nearestListReward?.name }}
          </p>
          <p class="primaryForeColor">
            ( {{ nearestListReward?.intouchPoints }} Points )
          </p>
        </div>
      </ng-container>
      <ng-container *ngIf="nearestListReward == undefined">
        <div routerLink="/redeem-rewards">
          <i class="giftImg">
            <img [src]="lessPointvoucher?.imageUrl" alt="gift image" />
          </i>
          <p class="primaryForeColor">
            You are
            <strong>{{ lessPointvoucher?.intouchPoints - userPoints }}</strong>
            Carepoints away from:
          </p>
          <p class="whiteForeColor text-uppercase">
            {{ lessPointvoucher?.name }}
          </p>
          <p class="primaryForeColor">
            ( {{ lessPointvoucher?.intouchPoints }} Points )
          </p>
        </div>
      </ng-container>
    </div>
    <div *ngIf="findGrothdairyflag == true">
      <div
        [hidden]="
          measurementData == 'Invalid user ID.' ||
          measurementData == 'User not registered in growth diary.' ||
          hideCont
        "
      >
        <h4 class="mt-3">Child Measurement Detail</h4>
        <div class="measurmentContainer my-4">
          <div class="measureLabel row mx-0">
            <div class="col-3 pr-0 pl-0">
              <p class="label">NAME</p>
            </div>
            <div class="col-3 pr-0 pl-0">
              <p class="label">
                LAST<br />
                MEASURED DATE
              </p>
            </div>
            <div class="col-3 pr-0">
              <p class="label">
                LAST<br />
                MEASURED HEIGHT
              </p>
            </div>
            <div class="col-3 pr-0 align-items-end d-flex">
              <p class="label">RESULTS</p>
            </div>
          </div>
          <div
            class="measureUnits row mx-0 mt-3"
            *ngFor="let data of measurementData"
          >
            <div class="col-3 pr-0 pl-0">
              <p class="label">{{ data?.name }}</p>
            </div>
            <div class="col-3 pr-0 pl-0">
              <p class="label text-uppercase">
                {{ data?.last_measure_date | date: "dd-MMM yy" }}
              </p>
            </div>
            <div class="col-3 pr-0">
              <p class="label">{{ data?.height }} CM</p>
            </div>
            <div class="col-3 pr-0">
              <p class="label">{{ data?.height_status }}</p>
            </div>
          </div>

          <div class="text-center mt-3">
            <button
              class="btn measureBtn"
              (click)="gotoHistory()"
              type="button"
            >
              TAKE MEASUREMENT NOW
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row two-rectangles mt-5">
      <div class="whiteBtn-tab" (click)="onClickToScan()">
        <div class="images">
          <img src="../../../assets/images/Group 304.png" />
        </div>
        <div class="content">
          <p class="blueBtn">scan for <br />loyalty points</p>
        </div>
      </div>
      <div class="whiteBtn-tab" [routerLink]="['/points-status']">
        <div class="images">
          <img src="../../../assets/images/Group 31.png" />
        </div>
        <div class="content">
          <p class="blueBtn">
            check <br />
            carepoints
          </p>
        </div>
      </div>
    </div>

    <!-- <div *ngIf="findGrothdairyflag == true">
        <div [hidden]="webquloPointData == 'Invalid user ID.' || webquloPointData == 'User not registered in growth diary.' || hideCont">
            <h4 class="mt-3">Pediasure Growth Diary</h4>
            <div class="measurmentContainer my-4"  >
                <div class="measureLabel row mx-0">
                    <div class="col-4 pr-0 pl-0">
                        <p class="label">DATE</p>
                    </div>
                    <div class="col-3 pr-0 pl-0">
                        <p class="label">POINTS</p>
                    </div>
                    <div class="col-5 pr-0">
                        <p class="label">DESCRIPTION</p>
                    </div>
                </div>
                <div class="measureUnits row mx-0 mt-3" *ngFor="let wqdata of webquloPointData">
                
                    <div class="col-4 pr-0 pl-0">
                        <p class="label text-uppercase" >{{wqdata?.point_date | date: 'dd-MMM yy'}}</p>
                    </div>
                    <div class="col-3 pl-0 pr-0">
                        <p class="label">{{wqdata?.point }} points</p>
                    </div>
                    <div class="col-5 pr-0">
                        <p class="label">{{wqdata?.description }}</p>
                    </div>
                    
                </div>
            </div>
        </div>
        </div> -->
    <!-- <div *ngIf="referralList?.length > 0">
            <h4 class="mt-3">Friend Referral Points</h4>
            <div class="measurmentContainer my-4"  >
                <div class="measureLabel row mx-0">
                    <div class="col-6 pr-0 pl-0">
                        <p class="label">POINTS</p>
                    </div>
                    <div class="col-6 pr-0">
                        <p class="label">ISSUED DATE</p>
                    </div>
                </div>
                <div class="measureUnits row mx-0 mt-3" *ngFor="let promotion of referralList">
                    <div class="col-6 pl-0 pr-0">
                        <p class="label">{{promotion?.points }} points</p>
                    </div>
                    <div class="col-6 pr-0">
                        <p class="label">{{promotion?.date | date: 'dd-MMM yy'}}</p>
                    </div>
                    
                </div>
            </div>
        </div> -->
    <!-- action=" https://pediasure-growth-staging.uidhosting.com" -->
    <!-- Live: https://pediasuregrowthdiary.com.my/  -->
    <div class="container">
      <form
        method="post"
        action="http://pediasuregrowthdiary.com.my/"
        id="formSubmit"
      >
        <input
          id="access_token"
          type="hidden"
          name="access_token"
          value="access_token"
        />
        <input id="auth_key" type="hidden" name="auth_key" value="auth_key" />
        <input
          id="auth_secret"
          type="hidden"
          name="auth_secret"
          value="auth_secret"
        />
        <input id="mobile" type="hidden" name="mobile" value="mobile" />
        <input
          id="otp_token"
          type="hidden"
          name="otp_token"
          value="otp_token"
        />
        <input id="otp_key" type="hidden" name="otp_key" value="otp_key" />
        <input
          id="Pediasure_Obj"
          type="hidden"
          name="Pediasure_Obj"
          value="Pediasure_Obj"
        />
        <input
          id="target_page"
          type="hidden"
          name="target_page"
          value="target_page"
        />

        <!-- <button type="button" va id="buttonSubmit">submit</button> -->
      </form>
    </div>
    <div class="updatesOut" *ngIf="productList?.length > 0">
      <!-- <h6>{{findDay}}</h6> -->
      <!-- <span>{{lastTranasction}}</span> -->
      <!-- <span>11:34, 24/04/21</span> -->
      <h6>Last Transaction</h6>
      <span>{{ lastTransactionDate | date: "dd-MM-yyyy" }}</span>
    </div>
    <div class="pointsList">
      <div class="container points-statusContainer">
        <div *ngFor="let product of productList">
          <div class="points-statusList">
            <div class="image">
              <img *ngIf="product.image != 'null'" [src]="product.image" />
              <img
                *ngIf="product.image == 'null'"
                src="../../../assets/images/abbott.svg"
              />
            </div>
            <div class="col-6 product-name">
              <p>{{ product.name }}</p>
              <p
                *ngIf="product.status == 'rejected' && product.reason"
                class="mt-1 viewDetails"
              >
                <a (click)="onClickViewDetails(product.reason)">View Details</a>
              </p>
            </div>

            <div class="col-4 status pr-0 text-right">
              <span
                style="
                  background: #fff;
                  color: #004c6c;
                  padding: 6px 16px;
                  border-radius: 15px;
                "
                *ngIf="product.status == 'pending'"
                [class.pending]="status === 'pending'"
                >Pending</span
              >
              <a
                *ngIf="product.status == 'pending'"
                class="validateLink"
                (click)="onclickUploadReceipt(product?.productCode)"
                >Click to validate</a
              >
              <span
                style="
                  background: #c92e26;
                  color: #fff;
                  padding: 6px 16px;
                  border-radius: 15px;
                "
                *ngIf="product.status == 'rejected'"
                [class.rejected]="status === 'rejected'"
                >Rejected</span
              >
              <a
                *ngIf="product.status == 'rejected'"
                class="validateLink"
                (click)="openReUploadImageModal(product?.productCode)"
                >Re Upload Receipt</a
              >

              <span
                style="
                  background: #66a443;
                  color: #fff;
                  padding: 6px 16px;
                  border-radius: 15px;
                "
                *ngIf="product.status == 'approved'"
                [class.confirm]="status === 'approved'"
                >Confirm</span
              >
              <strong>{{ product.points }}</strong>
              <span
                style="
                  background: orange;
                  color: #fff;
                  padding: 6px 16px;
                  border-radius: 15px;
                "
                *ngIf="product.status == 'awaiting'"
                [class.confirm]="status === 'awaiting'"
                >Awaiting</span
              >
            </div>
          </div>
        </div>

        <div class="no-scanPoints row" *ngIf="productList?.length == 0">
          <h5 class="no-transc-available col-12 pl-2 pr-0">
            No Transactions are Available
          </h5>
        </div>
        <!-- <ng-container *ngIf="pointsList?.length > 0">
                    <div class="row align-center mb20" [hidden]="point?.points?.issued <= 0" *ngFor="let point of pointsList">
                        <div class="col col-3">
                            <i class="img" *ngIf="point?.custom_fields?.field[4]?.name == 'productimage'"><img [src]="point?.custom_fields?.field[4]?.value" alt="pediasure vanila 850g" /></i>
                            <i class="img" *ngIf="point?.custom_fields?.field[5]?.name == 'productimage'"><img [src]="point?.custom_fields?.field[5]?.value" alt="pediasure vanila 850g" /></i>

                            <i class="img" *ngIf="point?.custom_fields?.field[0]?.name == ''"><img src="http://photos.prnewswire.com/prnfull/20150928/271488LOGO" alt="pediasure vanila 850g" /></i>
                           
                        </div>
                        <div class="col col-6">
                            <p class="text-uppercase" *ngIf="point?.custom_fields?.field[0]?.name == 'productname'">{{point?.custom_fields?.field[0]?.value}}</p>
                            <p class="text-uppercase" *ngIf="point?.custom_fields?.field[1]?.name == 'productname'">{{point?.custom_fields?.field[1]?.value}}</p>
                            <p class="text-uppercase" *ngIf="point?.custom_fields?.field[2]?.name == 'productname'">{{point?.custom_fields?.field[2]?.value}}</p>
                            
                            <p class="text-uppercase" *ngIf="point?.custom_fields?.field[0]?.name == ''">Product name unavailable</p>
                           
                        </div>
                        <div class="col col-3">
                            <p class="text-uppercase">+{{point?.points?.issued}} CAREPOINTS</p>
                        </div>
                    </div>
                </ng-container>
                <div class="no-scanPoints row" *ngIf="pointsList?.length == 0">
                    <h4 class="mb-4 px-0 col-12">Scanned Product Points</h4>
                    <h5 class="no-transc-available col-12 pl-2 pr-0"> No Transactions are Available </h5>
                </div> -->
      </div>
    </div>
  </div>

  <div
    bsModal
    #scanQrcodeGuideModal="bs-modal"
    class="modal fade customModalContainer px30"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-sm mx-auto">
      <div class="modal-content">
        <div class="modal-body pt-3">
          <div class="qrScanGuideModal">
            <div class="text-center px15">
              <span class="title fs16 mb-0">Where To Find The QR Code</span>
              <span class="mb-0 text1">
                *Only applicable for participating products</span
              >
              <img
                class="br10 mt-3 w-100p"
                src="../../../assets/images/Group 122.png"
              />
              <p class="textPureBlack mt-2 mb-0">1. Scan QR code on the box</p>
              <span class="title mt-2">OR</span>
              <img
                class="br10 mt-3 w-100p"
                src="../../../assets/images/Group 118.png"
              />
              <p class="textPureBlack mt-2">2. Scan QR code under the tin</p>
            </div>
            <div class="buttonOk">
              <button class="btnOk text-center" [routerLink]="['/qr-scanner']">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    bsModal
    #reasonModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeReasonModal()"
          >
            x
          </button>
          <p class="text-center contactInfo">
            {{ rejectReason }}
          </p>
          <hr />
          <p class="text-center contactInfo">
            If you think this is an error, please call
            <a href="tel:1800-88-6233"> 1800-88-6233</a> or email us at
            <a href="mailto:m.bluth@example.com">wecare@abbott.com</a>
          </p>

          <div class="text-center">
            <button
              type="submit"
              class="blueBtn text-white mt-3"
              (click)="closeReasonModal()"
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    bsModal
    #reUploadImageModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeReUploadImageModal()"
          >
            x
          </button>

          <p class="text-center contactInfo">
            By proceeding, you have opted to re-upload a valid receipt for
            validation and will be subjected to the same
            <a routerLink="/">terms & conditions</a>
          </p>

          <div class="text-center">
            <button
              type="submit"
              class="blueBtn text-white mt-3"
              (click)="onclickReUploadImageReceipt()"
            >
              RE-UPLOAD RECEIPT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-version></app-version>
  <div class="-mx-20">
    <app-footer></app-footer>
  </div>
</div>
<div
  bsModal
  #loginPopup="bs-modal"
  class="modal fade login_landing"
  tabindex="-1"
  role="dialog"
  [config]="{backdrop: 'static'}"
    aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm loginPopup">
    <div class="modal-content">
      <div>
        <button
          type="button"
          class="close mr-2"
          aria-label="Close"
          (click)="closeloginPopup()"
        >
          x
        </button>
      </div>
      <div class="modal-body">
        <!-- <button type="button" class="close" aria-label="Close" (click)="closeloginLandingModal()">
                    x
                </button> -->
        <!-- <p class="text-center">Sign up or login to capture your CarePoints.</p> -->
       <img src="../../../assets/images/login_popup.png" alt="loginpopup" />
      </div>
    </div>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>
