<app-header-logged-out></app-header-logged-out>
<div class="blueBg _h100vh">
  <div *ngIf="readonly" class="login-btn">
    <button class="blueBtn" (click)="handleLoginBtn()">Login</button>
  </div>
  <div class="whiteBg">
    <div class="formOut">
      <h3 class="formTitle">sign up</h3>
      <form
        [formGroup]="registerForm"
        (ngSubmit)="onRegisterFormSubmit()"
        *ngIf="!showOtp"
      >
        <!-- *ngIf="showSignUp" -->
        <div class="form-group">
          <label>mobile number</label>
          <div class="row">
            <div class="col-3 pr-1">
              <div class="form-group">
                <!-- <label>postal code</label> -->
                <input
                  type="text"
                  class="countryCodeAlign form-control"
                  formControlName="countryCode"
                  placeholder="+60"
                  disabled
                />
              </div>
            </div>
            <div class="col-9 pl-0">
              <div class="form-group">
                <!-- <label>postal code</label> -->
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && fVal.mobileNumber.errors
                  }"
                  formControlName="mobileNumber"
                  (change)="checkSalesForce($event)"
                  maxlength="11"
                  (keypress)="onlyNumbers($event)"
                />
                <div
                  *ngIf="submitted && fVal.mobileNumber.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="fVal.mobileNumber.errors.required">
                    Enter Mobile number
                  </div>
                  <div *ngIf="fVal.mobileNumber.errors.minlength">
                    Mobile number must be atleast 9 digits
                  </div>
                  <div *ngIf="fVal.mobileNumber.errors.maxlength">
                    Mobile number must be atmost 11 digits
                  </div>
                </div>
                <p class="contentDarkBlue mt-2">
                  Please enter full handphone number beginning with 1. Eg:
                  Handphone number 012-3899000. Key in 123899000.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>first name</label>
          <input
            type="text"
            class="form-control"
            formControlName="firstName"
            [ngClass]="{ 'is-invalid': submitted && fVal.firstName.errors }"
            (keypress)="onlyCharacters($event)"
          />
          <div
            *ngIf="submitted && fVal.firstName.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fVal.firstName.errors.required">Enter First Name</div>
            <div
              *ngIf="
                fVal.firstName.errors.firstName || fVal.firstName.errors.pattern
              "
            >
              Enter only alphabets
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Last name</label>
          <input
            type="text"
            class="form-control"
            formControlName="lastName"
            [ngClass]="{ 'is-invalid': submitted && fVal.lastName.errors }"
            (keypress)="onlyCharacters($event)"
          />
          <div
            *ngIf="submitted && fVal.lastName.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fVal.lastName.errors.required">Enter Last Name</div>
            <div
              *ngIf="
                fVal.lastName.errors.lastName || fVal.lastName.errors.pattern
              "
            >
              Enter only alphabets
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>EMAIL ADDRESS</label>
          <input
            type="text"
            class="form-control"
            formControlName="email"
            [ngClass]="{
              'is-invalid':
                submitted && (fVal.email.errors || emailAlreadyPresent)
            }"
            (keydown)="emailInput($event)"
          />
          <div *ngIf="submitted && fVal.email.errors" class="invalid-feedback">
            <div *ngIf="fVal.email.errors.required">Enter Email address</div>
            <div *ngIf="fVal.email.errors.pattern">
              Please input correct email
            </div>
          </div>
          <div *ngIf="submitted && emailAlreadyPresent" class="invalidText">
            Email address has been registered
          </div>
        </div>

        <!-- <div class="form-group">
                    <label>surname</label>
                    <input type="text" class="form-control" formControlName="surName"
                        [ngClass]="{ 'is-invalid': submitted && fVal.surName.errors }" />
                    <div *ngIf="submitted && fVal.surName.errors" class="invalid-feedback">
                        <div *ngIf="fVal.surName.errors.required">Enter SurName </div>
                    </div>
                </div> -->
        <!-- <div class="form-group">
                    <label>address line 1</label>
                    <textarea class="form-control" formControlName="addressLine1"
                        [ngClass]="{ 'is-invalid': submitted && fVal.addressLine1.errors }">
                    </textarea>
                    <div *ngIf="submitted && fVal.addressLine1.errors" class="invalid-feedback">
                        <div *ngIf="fVal.addressLine1.errors.required">Enter Address Line1 </div>
                    </div>
                </div> -->
        <!-- <div class="form-group">
                    <label>address line 2</label>
                    <textarea class="form-control" formControlName="addressLine2"
                        [ngClass]="{ 'is-invalid': submitted && fVal.addressLine2.errors }">
                    </textarea>
                    <div *ngIf="submitted && fVal.addressLine2.errors" class="invalid-feedback">
                        <div *ngIf="fVal.addressLine2.errors.required">Enter Address Line2 </div>
                    </div>
                </div> -->
        <!-- <div class="form-group">
                    <label>address line 3</label>
                    <textarea class="form-control" formControlName="addressLine3"
                        [ngClass]="{ 'is-invalid': submitted && fVal.addressLine3.errors }">
                    </textarea>
                    <div *ngIf="submitted && fVal.addressLine3.errors" class="invalid-feedback">
                        <div *ngIf="fVal.addressLine3.errors.required">Enter Address Line3 </div>
                    </div>
                </div> -->
        <!-- <div class="form-group">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label>postal code</label>
                                <input type="text" class="form-control" formControlName="postalCode"
                                    [ngClass]="{ 'is-invalid': submitted && fVal.postalCode.errors }" />
                                <div *ngIf="submitted && fVal.postalCode.errors" class="invalid-feedback">
                                    <div *ngIf="fVal.postalCode.errors.required">Enter Postal Code </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>country</label>
                                <div class="customSelect">
                                    <select class="form-control" formControlName="country"
                                        [ngClass]="{ 'is-invalid': submitted && fVal.country.errors }" disabled>
                                        <option value="" selected >malaysia</option>
                                        <option>state 1</option>
                                        <option>state 2</option>
                                    </select>
                                    <div *ngIf="submitted && fVal.country.errors" class="invalid-feedback">
                                        <div *ngIf="fVal.country.errors.required">Select Country </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
        <div class="form-group">
          <label>Select favourite brand</label>
          <div class="customSelect">
            <select
              class="form-control"
              formControlName="selectFavouriteBrand"
              [ngClass]="{
                'is-invalid': submitted && fVal.selectFavouriteBrand.errors
              }"
            >
              <option value="" selected>SELECT FAVOURITE BRAND</option>
              <option>Pediasure</option>
              <option>Ensure</option>
              <option>Glucerna</option>
              <option>Similac mom</option>
              <option>Similac gain plus gold</option>
              <option>Similac gain kid gold</option>
              <option>Isomil plus</option>
              <option>PEDIASURE 10+</option>
              <option>SIMILAC INTELLI-PRO</option>
            </select>
            <div
              *ngIf="submitted && fVal.selectFavouriteBrand.errors"
              class="invalid-feedback"
            >
              <div *ngIf="fVal.selectFavouriteBrand.errors.required">
                Select selectFavouriteBrand
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label
            >HAVE YOU BEEN CONSUMING THIS BRAND WITHIN THE PAST 12
            MONTHS?</label
          >
          <div class="flex-center">
            <div style="display: flex">
              <input
                type="radio"
                value="true"
                class="form-control-radio"
                formControlName="productUsage"
              /><label style="margin-left: 4px; margin-top: 10px">Yes</label>
            </div>

            <div style="display: flex">
              <input
                type="radio"
                value="false"
                class="form-control-radio"
                formControlName="productUsage"
              /><label style="margin-left: 4px; margin-top: 10px">No</label>
            </div>
          </div>

          <div *ngIf="submitted && fVal.productUsage.errors" class="feedback">
            <div *ngIf="fVal.productUsage.errors.required">
              Please choose one option
            </div>
          </div>
          <div
            *ngIf="submitted && fVal.productUsage.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fVal.productUsage.errors.required">
              Please choose one option
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Password</label>
          <input
            type="password"
            class="form-control"
            formControlName="password"
            [ngClass]="{ 'is-invalid': submitted && fVal.password.errors }"
          />
          <div
            *ngIf="submitted && fVal.password.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fVal.password.errors.required">
              password is required
            </div>
          </div>
          <p class="contentDarkBlue mt-2">
            Password must have minimum 8 characters, contain at least 1 numeric
            character and 1 special character
          </p>
        </div>
        <div class="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            class="form-control"
            formControlName="confirmPassword"
            [ngClass]="{
              'is-invalid': submitted && fVal.confirmPassword.errors
            }"
          />
          <div
            *ngIf="submitted && fVal.confirmPassword.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fVal.confirmPassword.errors.required">
              Password is required
            </div>
            <div *ngIf="fVal.confirmPassword.errors.mustMatch">
              Password confirmation does not match
            </div>
          </div>
        </div>
        <!-- <div class="form-group">
          <label>REFERRER CODE (IF ANY)</label>
          <input
            type="text"
            class="form-control"
            formControlName="referrerCode"
            [readonly]="readonly"
          />
        </div> -->
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            formControlName="subscription"
            [ngClass]="{ 'is-invalid': submitted && fVal.subscription.errors }"
          />
          <label class="form-check-label"
            >I have read and understood the terms of this
            <a href="https://abbottnutrition.com.my/privacy-policy"
              >Privacy Notice</a
            >
            and consent to the processing of my Personal Data as described. I
            would like to receive communication from Abbott by phone / email /
            SMS / Whatsapp regarding Abbott products and services, or for
            research purposes. I also authorize Abbott to disclose my personal
            data to selected third parties to deliver value added services to me
            as an Abbott Share & Care World (ASCW) Club member.</label
          >
          <div
            *ngIf="submitted && fVal.subscription.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fVal.subscription.errors.required">
              This is required
            </div>
          </div>
        </div>
        <div class="form-group">
          <re-captcha
            formControlName="recaptcha"
            (resolved)="resolved($event)"
            (error)="errored($event)"
            siteKey="{{ getSiteKey() }}"
            [ngClass]="{ 'is-invalid': submitted && fVal.recaptcha.errors }"
            required
          >
          </re-captcha>
          <div
            *ngIf="submitted && fVal.recaptcha.errors"
            class="invalid-feedback reCaptchaError"
          >
            <div *ngIf="fVal.recaptcha.errors.required">
              Recaptcha is required
            </div>
          </div>
        </div>
        <div class="text-center">
          <button
            type="submit"
            class="blueBtn"
            [ngClass]="{ isValidSubmit: registerForm.valid }"
          >
            SIGN UP
          </button>
        </div>
      </form>
      <form
        [formGroup]="otpForm"
        (ngSubmit)="onSubmitOtpForm()"
        *ngIf="showOtp"
      >
        <div class="form-group text-center">
          <!-- <label>OTP</label>
                    <input type="password" class="form-control" formControlName="otp" maxlength="6"
                        [ngClass]="{ 'is-invalid': submittedOtp && OtpFval.otp.errors || invalidOtp}" 
                        (keydown)="otpInput($event)"/>
                    <div *ngIf="submittedOtp && OtpFval.otp.errors" class="invalid-feedback">
                        <div *ngIf="OtpFval.otp.errors.required">Enter otp</div>
                        <div *ngIf="OtpFval.otp.errors.minlength">otp should be 6 characters</div>
                        <div *ngIf="OtpFval.otp.errors.maxlength">otp should be 6 characters</div>
                    </div>
                    <div *ngIf="invalidOtp" class="invalidText">
                        <div>Invalid Otp</div>
                    </div> -->
          <p class="mb-0 contentSmall">
            OTP has been sent to your mobile number
          </p>
          <p class="mb-0 contentSmall">Please enter it below:</p>
          <div class="text-center mt-3" *ngIf="!showResendOtp">
            (<span class="contentSmall" *ngIf="beginTimer">
              Resend Otp in
              <countdown
                #cd
                [config]="{ leftTime: 60, format: 'mm:ss', notify: [2, 5] }"
                (event)="handleEvent($event)"
              ></countdown> </span
            >)
            <br />
          </div>
          <ng-otp-input
            (onInputChange)="onOtpChange($event)"
            [config]="{ length: 6, placeholder: '0', allowNumbersOnly: true }"
            [ngClass]="{
              'is-invalid':
                (submittedOtp && (otp == undefined || otp.length < 6)) ||
                invalidOtp
            }"
            #ngOtpInput
          >
          </ng-otp-input>
          <div
            *ngIf="submittedOtp && OtpFval.otp.errors"
            class="text-danger pl-0"
          >
            <div *ngIf="!activeClassButton">Please Enter OTP</div>
          </div>
          <div *ngIf="invalidOtp" class="text-danger pl-0">
            <div>The entered OTP is incorrect</div>
          </div>
        </div>

        <div class="text-center mt-4">
          <button
            type="submit"
            [ngClass]="
              !activeClassButton
                ? 'lightBlueBtn mb-3 mt-3'
                : 'blueBtn mb-3 mt-3'
            "
          >
            submit
          </button>
        </div>
        <div class="text-center mt-4">
          <p class="contentSmall mb-2">Didn't receive otp?</p>
          <button *ngIf="!showResendOtp" class="lightBlueBtn" type="button">
            Resend otp
          </button>
          <button
            *ngIf="showResendOtp"
            class="blueBtn"
            type="button"
            (click)="resendOTP()"
          >
            Resend otp
          </button>
        </div>
        <div class="text-center mt-4">
          <button
            class="blueBtn text-uppercase"
            type="button"
            (click)="onClickChangeNumber()"
          >
            Change Number
          </button>
        </div>
      </form>
    </div>
  </div>
  <app-version></app-version>
</div>
<app-footer></app-footer>
<div
  bsModal
  #otpModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeOtpModal()"
        >
          x
        </button>
        <form [formGroup]="otpForm" (ngSubmit)="onSubmitOtpForm()">
          <div class="form-group">
            <label>OTP</label>
            <input
              type="password"
              class="form-control"
              formControlName="otp"
              maxlength="6"
              [ngClass]="{
                'is-invalid': (submittedOtp && OtpFval.otp.errors) || invalidOtp
              }"
              (keydown)="otpInput($event)"
            />
            <div
              *ngIf="submittedOtp && OtpFval.otp.errors"
              class="invalid-feedback"
            >
              <div *ngIf="OtpFval.otp.errors.required">Enter otp</div>
              <div *ngIf="OtpFval.otp.errors.minlength">
                otp should be 6 characters
              </div>
              <div *ngIf="OtpFval.otp.errors.maxlength">
                otp should be 6 characters
              </div>
            </div>
            <div *ngIf="invalidOtp" class="invalidText">
              <div>Invalid Otp</div>
            </div>
          </div>

          <div class="text-center" *ngIf="!showResendOtp">
            (<span class="primaryBlue font-weight-bold" *ngIf="beginTimer">
              Resend Otp in
              <countdown
                #cd
                [config]="{ leftTime: 60, format: 'mm:ss', notify: [2, 5] }"
                (event)="handleEvent($event)"
              ></countdown></span
            >) <br /><button class="colorGrey" type="button">Resend</button>
          </div>

          <div class="text-center" *ngIf="showResendOtp">
            <button
              type="submit"
              class="blueBtn text-white"
              (click)="resendOTP()"
            >
              Resend
            </button>
          </div>

          <div class="text-center">
            <button
              type="submit"
              class="blueBtn text-white mt-3"
              [ngClass]="{ isValidSubmit: otpForm.valid }"
            >
              submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #alreadyScanned="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeScannedModal()"
        >
          x
        </button>
        <p class="text-center">This QR code has been scanned.</p>

        <div class="text-center">
          <button
            type="submit"
            class="blueBtn text-white mt-3"
            (click)="closeScannedModal()"
          >
            ok
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #exceedLimitModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeRegisteredModal()"
        >
          x
        </button>
        <p class="text-center contactInfo">
          Sorry, the number of scans seemed to have exceeded the recommended
          monthly consumption limit. Please keep your product so that you can
          scan it again next month to collect CarePoints. For more information,
          please contact <a href="tel:1800-88-6233"> 1800-88-6233</a> or email
          us at
          <a href="mailto:m.bluth@example.com">wecare@abbott.com</a>
        </p>

        <div class="text-center">
          <button
            type="submit"
            class="blueBtn text-white mt-3"
            (click)="closeRegisteredModal()"
          >
            ok
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #alreadyRegistered="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeRegisteredModal()"
        >
          x
        </button>
        <p class="text-center">This handphone number has been registered.</p>

        <div class="text-center">
          <button
            type="submit"
            class="blueBtn text-white mt-3"
            (click)="closeRegisteredModal()"
          >
            ok
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <app-bottom-nav></app-bottom-nav> -->
