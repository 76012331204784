import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SafePipeModule } from 'safe-pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopupModule } from '@ciri/ngx-popup';
import { AppRoutingModule } from './app-routing.module';
import { NgOtpInputModule } from 'ng-otp-input';
import { CountdownModule } from 'ngx-countdown';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

import { AppComponent } from './app.component';
import { ApiService } from './services/api.service';
import { HomeComponent } from './pages/home/home.component';
import { ProductInfoComponent } from './pages/product-info/product-info.component';
import { HeaderComponent } from './shared/header/header.component';
import { LandingComponent } from './landing/landing.component';
import { TraceabilityEnsureComponent } from './pages/ensure/traceability-ensure/traceability-ensure.component';
import { TraceabilitySimlilacComponent } from './pages/similac/traceability-simlilac/traceability-simlilac.component';
import { HeaderSecondaryComponent } from './shared/header-secondary/header-secondary.component';
import { TraceabilityPediasureComponent } from './pages/pediasure/traceability-pediasure/traceability-pediasure.component';
import { SourceOfIngredientsComponent } from './pages/ensure/source-of-ingredients/source-of-ingredients.component';
import { DeliveryComponent } from './pages/ensure/delivery/delivery.component';
import { ManufacturingComponent } from './pages/ensure/manufacturing/manufacturing.component';
import { QualitycontrolComponent } from './pages/ensure/qualitycontrol/qualitycontrol.component';
import { EnsuresharecareComponent } from './pages/ensure/ensuresharecare/ensuresharecare.component';
import { EnsurehmbtripleproteinComponent } from './pages/ensure/ensurehmbtripleprotein/ensurehmbtripleprotein.component';
import { WhyensureComponent } from './pages/ensure/whyensure/whyensure.component';
import { EnsureBottomnavComponent } from './shared/ensure-bottomnav/ensure-bottomnav.component';
import { PediasureSharecareComponent } from './pages/pediasure/pediasure-sharecare/pediasure-sharecare.component';
import { PediasureDeliveryComponent } from './pages/pediasure/pediasure-delivery/pediasure-delivery.component';
import { PediasureQualitycontrolComponent } from './pages/pediasure/pediasure-qualitycontrol/pediasure-qualitycontrol.component';
import { PediasureManufacturingComponent } from './pages/pediasure/pediasure-manufacturing/pediasure-manufacturing.component';
import { PediasureNaturalvitaminComponent } from './pages/pediasure/pediasure-naturalvitamin/pediasure-naturalvitamin.component';
import { PediasureBottomnavComponent } from './shared/pediasure-bottomnav/pediasure-bottomnav.component';
import { SimilacManufacturingComponent } from './pages/similac/similac-manufacturing/similac-manufacturing.component';
import { SimilacMilksourceComponent } from './pages/similac/similac-milksource/similac-milksource.component';
import { SimilacQualitycontrolComponent } from './pages/similac/similac-qualitycontrol/similac-qualitycontrol.component';
import { SimilacDeliveryComponent } from './pages/similac/similac-delivery/similac-delivery.component';
import { SimilacSharecareComponent } from './pages/similac/similac-sharecare/similac-sharecare.component';
import { SimilacWhychooseComponent } from './pages/similac/similac-whychoose/similac-whychoose.component';
import { SimilacNucleotidesComponent } from './pages/similac/similac-nucleotides/similac-nucleotides.component';
import { SimilacBottomnavComponent } from './shared/similac-bottomnav/similac-bottomnav.component';
import { ProductsComponent } from './pages/products/products.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { HeaderPrimaryComponent } from './shared/header-primary/header-primary.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RegisterComponent } from './pages/register/register.component';
import { CarePointsComponent } from './pages/care-points/care-points.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RedeemRewardsComponent } from './pages/redeem-rewards/redeem-rewards.component';
import { VersionComponent } from './pages/version/version.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { RedeemRewardDetailsComponent } from './pages/redeem-reward-details/redeem-reward-details.component';
import { EnsureDetailsComponent } from './pages/ensure-details/ensure-details.component';
import { SomethingNewComponent } from './pages/something-new/something-new.component';
import { SimilacDetailsComponent } from './pages/similac-details/similac-details.component';
import { PediasureDetailsComponent } from './pages/pediasure-details/pediasure-details.component';
import { BottomNavComponent } from './pages/bottom-nav/bottom-nav.component';
import { UpdateMobileComponent } from './pages/update-mobile/update-mobile.component';
import { RedemptionSuccessComponent } from './pages/redemption-success/redemption-success.component';
import { RedemptionHistoryComponent } from './pages/redemption-history/redemption-history.component';
import { LanguageSelectComponent } from './shared/language-select/language-select.component';
import { HeaderSecondaryCopyComponent } from './shared/header-secondary-copy/header-secondary-copy.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ReferFriendComponent } from './pages/refer-friend/refer-friend.component';
import { HeaderLoggedOutComponent } from './shared/header-logged-out/header-logged-out.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ScanComponent } from './pages/scan/scan.component';
import { QrScannerComponent } from './pages/qr-scanner/qr-scanner.component';
import { PointsStatusComponent } from './pages/points-status/points-status.component';
import { ValidateReceiptComponent } from './pages/validate-receipt/validate-receipt.component';
import { CameraComponent } from './pages/camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { ExposeComponent } from './pages/expose/expose.component';
import { BlockUserComponent } from './pages/block-user/block-user.component';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { AuthInterceptor } from './_helpers/auth.interceptor';
import { environment as env } from '../environments/environment';
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

Bugsnag.start({
  apiKey: '99c5d5ae2570cc6a07e1dc35b5d0fc1c',
  appVersion: env.bugsnag_version,
})

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductInfoComponent,
    HeaderComponent,
    LandingComponent,
    TraceabilityEnsureComponent,
    TraceabilitySimlilacComponent,
    HeaderSecondaryComponent,
    TraceabilityPediasureComponent,
    SourceOfIngredientsComponent,
    DeliveryComponent,
    ManufacturingComponent,
    QualitycontrolComponent,
    EnsuresharecareComponent,
    EnsurehmbtripleproteinComponent,
    WhyensureComponent,
    EnsureBottomnavComponent,
    PediasureSharecareComponent,
    PediasureDeliveryComponent,
    PediasureQualitycontrolComponent,
    PediasureManufacturingComponent,
    PediasureNaturalvitaminComponent,
    PediasureBottomnavComponent,
    SimilacManufacturingComponent,
    SimilacMilksourceComponent,
    SimilacQualitycontrolComponent,
    SimilacDeliveryComponent,
    SimilacSharecareComponent,
    SimilacWhychooseComponent,
    SimilacNucleotidesComponent,
    SimilacBottomnavComponent,
    ProductsComponent,
    LoginComponent,
    HeaderPrimaryComponent,
    AboutusComponent,
    ProfileComponent,
    RegisterComponent,
    CarePointsComponent,
    ForgotPasswordComponent,
    RedeemRewardsComponent,
    VersionComponent,
    RedeemRewardDetailsComponent,
    EnsureDetailsComponent,
    ChangePasswordComponent,
    RedeemRewardDetailsComponent,
    SomethingNewComponent,
    SimilacDetailsComponent,
    PediasureDetailsComponent,
    BottomNavComponent,
    UpdateMobileComponent,
    RedemptionSuccessComponent,
    RedemptionHistoryComponent,
    LanguageSelectComponent,
    HeaderSecondaryCopyComponent,
    DashboardComponent,
    FooterComponent,
    ReferFriendComponent,
    HeaderLoggedOutComponent,
    ScanComponent,
    QrScannerComponent,
    PointsStatusComponent,
    ValidateReceiptComponent,
    CameraComponent,
    ExposeComponent,
    BlockUserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientModule,
    SafePipeModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgOtpInputModule,
    CountdownModule,
    NgSelectModule,
    ClipboardModule,
    AccordionModule.forRoot(),
    ToastrModule.forRoot({ timeOut: 2000 }),
    FormsModule,
    ModalModule.forRoot(),
    WebcamModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ScrollToModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot(),
    ImageCropperModule,
    PopupModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    ApiService,
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: { siteKey: "6Le6JjQbAAAAAPgCD0_vPkFL64tMwj9peSOZ31PK" } as RecaptchaSettings,
    // }
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
